import { TSubtype, TSubtypes } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, Select } from '@marlin/shared/ui-form-common';
import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IUpsertDeviceForm } from '../use-upsert-device-form.hook';
import { MeterMultiplier } from './meter-multiplier.component';

export const valueToOption = (value: string) => ({
  id: value,
  name: value,
});

export interface IMeterModelControl {
  subtypes?: TSubtypes;
  timezoneName: string;
  childrenIds: string[];
  isCompound?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.typography.pxToRem(16),
    rowGap: theme.typography.pxToRem(32),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      rowGap: theme.typography.pxToRem(16),
    },
  },
  inputRow: {
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.typography.pxToRem(16),
    },
  },
  warningRow: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
  nestedInputRow: {
    gridColumn: '1 / -1',
  },
  calibrateButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  warningIcon: {
    marginTop: theme.typography.pxToRem(4),
  },
  endAdornment: {
    marginRight: theme.typography.pxToRem(16),
  },
  modal: {
    width: theme.typography.pxToRem(824),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxHeight: '100%',
      overflowY: 'auto',
    },
  },
  multiplierLabel: {
    marginBottom: `-${theme.typography.pxToRem(8)}`,
  },
  channelSection: {
    display: 'flex',
    width: '100%',
    gap: theme.typography.pxToRem(16),
    alignItems: 'center',
  },
}));

export const MeterModelControl = ({ subtypes, timezoneName, childrenIds, isCompound }: IMeterModelControl) => {
  const { classes } = useStyles();
  const { watch, setValue, trigger } = useFormContext<IUpsertDeviceForm>();
  const [subtypeValues, setSubtypeValues] = useState<TSubtype[]>([]);

  const manufacturerName = watch('pulseMeterManufacturer');
  const modelName = watch('pulseMeterModel');
  const manufacturerId = watch('manufacturerId');
  const manufacturerOptions = useMemo(() => {
    return subtypes?.manufacturerList.map(valueToOption) ?? [];
  }, [subtypes?.manufacturerList]);

  const modelOptions = useMemo(() => {
    if (!manufacturerName) {
      return [];
    }
    return subtypes?.modelsListByManufacturers[manufacturerName]?.map(valueToOption) ?? [];
  }, [manufacturerName, subtypes?.modelsListByManufacturers]);

  useEffect(() => {
    if (modelName?.length && manufacturerName?.length) {
      const chosenSubtypes = subtypes?.modelsListByCompoundType[manufacturerName][modelName];

      if (!chosenSubtypes) {
        return;
      }
      setValue('pulseMeterTypeId', chosenSubtypes[0].id);
      setValue(
        'pulseMeterTypeIds',
        chosenSubtypes.map(({ id }, index) => ({ pulseMeterTypeId: id, order: index }))
      );
      setSubtypeValues(chosenSubtypes);
      trigger();
    } else {
      setValue('pulseMeterTypeId', '');
      setValue('pulseMeterTypeIds', []);
      setSubtypeValues([]);
    }
  }, [manufacturerName, modelName, setValue, subtypes?.modelsListByCompoundType, trigger]);

  return (
    <div className={classes.container}>
      <FormField<IUpsertDeviceForm> fieldName="pulseMeterManufacturer">
        {(props) => {
          return (
            <Select
              {...props}
              onChange={(e) => {
                setValue('pulseMeterModel', '');
                setValue('pulseMeterTypeId', '');
                setValue('pulseMeterTypeIds', []);
                setSubtypeValues([]);
                props.onChange && props.onChange(e);
              }}
              value={props.value ?? ''}
              data={manufacturerOptions}
              prefix={'meter-manufacturer'}
              label={content.METER_MANUFACTURER_LABEL}
              emptyOption=" "
              fullWidth
              required
            />
          );
        }}
      </FormField>

      <FormField<IUpsertDeviceForm> fieldName="pulseMeterModel">
        {(props) => (
          <Select
            {...props}
            value={props.value ?? ''}
            data={modelOptions}
            label={content.METER_MODEL_LABEL}
            prefix={'meter-model'}
            fullWidth
            emptyOption=" "
            disabled={!manufacturerName}
            required
            onChange={(e) => {
              setValue('pulseMeterTypeId', '');
              setValue('pulseMeterTypeIds', []);
              setSubtypeValues([]);
              props.onChange && props.onChange(e);
            }}
          />
        )}
      </FormField>
      <Typography className={classes.multiplierLabel}>{content.MULTIPLIER}</Typography>
      {manufacturerName &&
        subtypeValues.map((subtype, index) => (
          <MeterMultiplier
            subtype={subtype}
            childrenIds={childrenIds}
            timezoneName={timezoneName}
            index={index}
            manufacturerId={manufacturerId}
            key={subtype.id}
          />
        ))}
    </div>
  );
};
