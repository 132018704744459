import { MarlinTheme } from '@marlin/shared/theme';
import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import { useContext, useMemo } from 'react';
import {
  Handle,
  NodeDimensionChange,
  NodeProps,
  NodeResizeControl,
  Position,
  ResizeDragEvent,
  ResizeParams,
  getNodesBounds,
  useReactFlow,
  useStore,
} from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { EditModeContext } from '../../../edit-mode.context';
import { useTouchedState$ } from '../../buttons/use-observable-touched-state.hook';
import { nodePadding } from '../../const';
import { calculateSnappedValue } from '../../use-snap-to-grid.hook';
import { useCommonStyles } from '../common.styles';

interface INodeStyleProps {
  width: number;
  height: number;
  ancestors: number;
}

const calculateParentColor = (ancestors: number, theme: MarlinTheme): string => {
  const shouldUseSecondaryColor = ancestors % 2 === 1;
  if (shouldUseSecondaryColor) {
    return theme.palette.systemMap.group;
  }
  return theme.palette.primary.contrastText;
};

export const useStyles = makeStyles<INodeStyleProps>()((theme: MarlinTheme, { width, height, ancestors }) => ({
  location: {
    backgroundColor: theme.palette.systemMap.location,
    borderStyle: 'dashed',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
  },
  groupLocation: {
    backgroundColor: calculateParentColor(ancestors, theme),
    padding: 0,
    minHeight: height,
    height: '100%',
    minWidth: width,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
  },
  groupLabel: {
    position: 'relative',
    top: height,
  },
  resizeControl: {
    '&.react-flow__resize-control.handle': {
      transform: `translate(-${theme.typography.pxToRem(32)}, -${theme.typography.pxToRem(32)})`,

      background: 'transparent',
      border: 'none',
    },
  },
  resizeIcon: {
    transform: 'rotate(90deg)',
  },
}));

type TNodeData = TGraphAsset['data'] & { label: string };

type TIsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
};

function isEqual(prev: TIsEqualCompareObj, next: TIsEqualCompareObj): boolean {
  return prev.minWidth === next.minWidth && prev.minHeight === next.minHeight;
}

export const GroupLocationNode = ({ data, id }: NodeProps<TNodeData>) => {
  const { isEditMode } = useContext(EditModeContext);
  const [, setTouched] = useTouchedState$(false);
  const { getNode } = useReactFlow();
  const node = getNode(id);

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeInternals.values()).filter((n) => n.parentNode === id);
    const rect = getNodesBounds(childNodes);

    return {
      minWidth: calculateSnappedValue(
        Math.abs(rect.x - (node?.positionAbsolute?.x || rect.x)) + rect.width + nodePadding
      ),
      minHeight: calculateSnappedValue(
        Math.abs(rect.y - (node?.positionAbsolute?.y || rect.y)) + rect.height + nodePadding
      ),
    };
  }, isEqual);

  const { width, height } = useMemo(() => {
    const nodeWidth = calculateSnappedValue(node?.width ?? 10 + nodePadding);
    const nodeHeight = calculateSnappedValue(node?.height ?? 10 + nodePadding);

    return {
      width: Math.max(minWidth, nodeWidth),
      height: Math.max(minHeight, nodeHeight),
    };
  }, [node?.width, node?.height, minWidth, minHeight]);

  const triggerNodeChanges = useStore((store) => store.triggerNodeChanges);

  const ancestors = useStore((store) => {
    const nodes = Array.from(store.nodeInternals.values());
    const getNumberOfAncestors = (nodeId: string): number => {
      const node = nodes.find((n) => n.id === nodeId);
      if (node && node.parentNode) {
        return 1 + getNumberOfAncestors(node.parentNode);
      }
      return 1;
    };

    return getNumberOfAncestors(id);
  });

  const { classes, cx } = useStyles({ width, height, ancestors });
  const { classes: commonClasses } = useCommonStyles();

  return (
    <div
      className={cx(commonClasses.node, classes.location, classes.groupLocation)}
      data-testid={`location-node-${id}`}
    >
      <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
      <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
      <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
      <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
      <div data-testid={`location-node-${id}-group`}>
        <div className={classes.groupLabel} data-testid={`location-node-${id}-label`}>
          {data.name}
        </div>
      </div>
      {isEditMode && (
        <NodeResizeControl
          className={classes.resizeControl}
          minWidth={minWidth}
          minHeight={minHeight}
          onResizeStart={() => setTouched(true)}
          onResizeEnd={(_: ResizeDragEvent, params: ResizeParams) => {
            const dimensionChange: NodeDimensionChange = {
              id,
              type: 'dimensions',
              resizing: true,
              dimensions: {
                width: calculateSnappedValue(params.width),
                height: calculateSnappedValue(params.height),
              },
            };
            const endDimensionChange: NodeDimensionChange = {
              id: id,
              type: 'dimensions',
              resizing: false,
            };
            triggerNodeChanges([dimensionChange, endDimensionChange]);
          }}
        >
          <OpenInFullRoundedIcon className={classes.resizeIcon} />
        </NodeResizeControl>
      )}
    </div>
  );
};
