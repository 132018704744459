import { useGetGateway, useUpdateRegisteredGateway } from '@marlin/asset/data-access/gateway';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import {
  GATEWAY_REGISTRATION_ERROR,
  TUpsertGatewayForm,
  getRegistrationErrorCode,
} from '@marlin/shared/utils/gateway-utils';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useCallback, useMemo, useState } from 'react';

import { content } from '../content';

export const useUpdateGateway = () => {
  const gatewayId = useIdFromPathname();
  const gatewayQuery = useGetGateway({ gatewayId });
  const gateway = useMemo(() => gatewayQuery?.data, [gatewayQuery?.data]);
  const [registrationErrorCode, setRegistrationErrorCode] = useState<GATEWAY_REGISTRATION_ERROR | null>(null);
  const { goTo } = useRouter();
  const nextRoute = routes.gateway.list.path;

  const isLoading = useMemo(() => gatewayQuery.isFetching, [gatewayQuery.isFetching]);

  const updateRegisteredGatewayMutation = useUpdateRegisteredGateway();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (formValues: TUpsertGatewayForm) => {
      try {
        await updateRegisteredGatewayMutation.mutateAsync({
          data: {
            name: formValues.name,
            locationId: formValues.locationId,
            description: formValues.description,
            commissionDate: formValues.commissionDate,
          },
          gatewayId,
        });

        enqueueSnackbar(content.UPDATE_SUCCESS_MESSAGE, {
          variant: 'success',
          preventDuplicate: true,
        });

        goTo(nextRoute, { state: { shouldIgnoreGuard: true } });
      } catch (error) {
        const registrationErrorCode = getRegistrationErrorCode(error);

        if (registrationErrorCode) {
          setRegistrationErrorCode(registrationErrorCode);
        } else {
          enqueueSnackbar(content.CREATE_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        }
      }
    },
    [enqueueSnackbar, gatewayId, goTo, nextRoute, updateRegisteredGatewayMutation]
  );

  return {
    gateway,
    isLoading,
    onSubmit,
    gatewayId,
    registrationErrorCode,
  };
};
