import { zodResolver } from '@hookform/resolvers/zod';
import { useOrganizationLatestAddress } from '@marlin/account-data-access-onboarding';
import { useOrganizationList } from '@marlin/account-data-access-organization';
import { Loader } from '@marlin/shared/ui-loader';
import { router } from '@marlin/shared/utils-router';
import { ONBOARDING_TYPE } from '@marlin/shared/utils-routes';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

import { StepHeader } from '../../../components/step-header';
import { content } from '../../../content';
import { TSetupOrganization, setupOrganizationSchema } from '../../onboarding.schema';
import { defaultValues, useOnboardingState } from '../../use-onboarding-state.hook';
import { ONBOARDING_STEP } from '../steps';
import { useCancelModal } from './cancel-modal/cancel-modal.hook';
import { CreateNewOrganization } from './create-organization/create-organization.component';
import { SelectOrganization } from './select-organization/select-organization.component';
import { useStyles } from './setup-organization.styles';

interface ISetupOrganizationProps {
  onNextStep: (step: ONBOARDING_STEP, completed?: ONBOARDING_STEP[]) => void;
  onboardingType?: ONBOARDING_TYPE | null;
}

export const SetupOrganization = ({ onNextStep, onboardingType }: ISetupOrganizationProps) => {
  const { addAddress, onboardingState, clearOnboardingState } = useOnboardingState();
  const { openCancelModal } = useCancelModal();
  const organizationListQuery = useOrganizationList({ enabled: true });

  const { isFetching, data: addressData } = useOrganizationLatestAddress();

  if (addressData && !onboardingState.propertyAddress.address && !onboardingState.propertyAddress.name) {
    addAddress({
      data: { propertyAddress: { address: addressData, name: addressData.address1 } },
    });
  }

  const form = useForm<TSetupOrganization>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(setupOrganizationSchema),
  });

  const onCancel = () => {
    if (onboardingType === ONBOARDING_TYPE.CREATE_NEW_ORGANIZATION) {
      clearOnboardingState();
      router.goBack();
      return;
    }

    openCancelModal();
  };

  if (isFetching) {
    return <Loader />;
  }

  if (onboardingType !== ONBOARDING_TYPE.CREATE_NEW_ORGANIZATION && organizationListQuery.data?.length) {
    return (
      <SetupOrChooseOrganization
        form={form}
        onCancel={onCancel}
        onNextStep={onNextStep}
        organizationListQuery={organizationListQuery}
      />
    );
  }

  return (
    <div>
      <StepHeader title={content.SETUP_ORG_TITLE} subtitle={content.SETUP_ORG_SUBTITLE} />
      <CreateNewOrganization form={form} onCancel={onCancel} onNextStep={onNextStep} />
    </div>
  );
};

interface ISetupOrChooseOrganizationProps {
  form: UseFormReturn<TSetupOrganization>;
  onCancel: () => void;
  onNextStep: (step: ONBOARDING_STEP, completed?: ONBOARDING_STEP[]) => void;
  organizationListQuery: ReturnType<typeof useOrganizationList>;
}

const SetupOrChooseOrganization = ({
  form,
  onCancel,
  onNextStep,
  organizationListQuery,
}: ISetupOrChooseOrganizationProps) => {
  const { classes, cx } = useStyles();
  const { onboardingState, addOrganization } = useOnboardingState();
  const [option, setOption] = useState<'select' | 'create'>(
    onboardingState.propertyName && !onboardingState.organizationId ? 'create' : 'select'
  );
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, term, handleOrgSearchValue } =
    organizationListQuery;

  const isSelect = option === 'select';
  const isCreate = option === 'create';

  const onCreateClick = () => {
    addOrganization({ data: { organizationId: null }, organizationName: '' });

    setOption('create');
  };

  return (
    <div>
      <StepHeader title={content.SETUP_ORG_TITLE} subtitle="" />
      <div className={classes.tileContainer}>
        <Paper
          variant="outlined"
          className={cx(classes.tile, { [classes.active]: isSelect })}
          onClick={() => setOption('select')}
          data-testid="select-your-organization"
        >
          <Typography variant="caption" align="center">
            {content.SELECT_ORGANIZATION}
          </Typography>
          {isSelect && <CheckCircleRoundedIcon className={classes.checkIcon} color="primary" />}
        </Paper>
        <Paper
          variant="outlined"
          className={cx(classes.tile, { [classes.active]: isCreate })}
          onClick={onCreateClick}
          data-testid="create-your-organization"
        >
          <Typography variant="caption" align="center">
            {content.CREATE_ORGANIZATION}
          </Typography>
          {isCreate && <CheckCircleRoundedIcon className={classes.checkIcon} color="primary" />}
        </Paper>
      </div>
      {isSelect && (
        <>
          <SectionHeader title={content.SELECT_ORGANIZATION} subtitle={content.SELECT_SUBTITLE} />
          <SelectOrganization
            organizations={data}
            onCancel={onCancel}
            onNextStep={onNextStep}
            hasNextPage={hasNextPage || false}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
            handleOrgSearchValue={handleOrgSearchValue}
            term={term}
          />
        </>
      )}
      {isCreate && (
        <>
          <SectionHeader title={content.CREATE_ORGANIZATION} subtitle={content.SETUP_ORG_SUBTITLE} />
          <CreateNewOrganization form={form} onCancel={onCancel} onNextStep={onNextStep} />
        </>
      )}
    </div>
  );
};

const SectionHeader = ({ title, subtitle }: { title: string; subtitle: string }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.section}>
      <Typography variant="caption" className={classes.sectionTitle} align="center">
        {title}
      </Typography>
      <Typography variant="caption" className={classes.sectionSubtitle} align="center">
        {subtitle}
      </Typography>
    </div>
  );
};
