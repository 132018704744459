import { dateAdapter } from '@marlin/shared/utils-common-date';
import { Moment } from 'moment/moment';

import { TBucketOption } from '../model';

export enum RANGE_FILTER {
  HOURS_8 = 'HOURS_8',
  HOURS_24 = 'HOURS_24',
  DAYS_7 = 'DAYS_7',
  DAYS_30 = 'DAYS_30',
  MONTHS_12 = 'MONTHS_12',
  CUSTOM = 'CUSTOM',
}

export interface IRangeFilter {
  range: RANGE_FILTER;
  currentDate: Moment;
}

export const isOfChartRangeType = (value: string): value is RANGE_FILTER =>
  Object.values(RANGE_FILTER).includes(value as RANGE_FILTER);

const rangeTime = new Map<RANGE_FILTER, () => Moment>([
  [RANGE_FILTER.HOURS_8, () => dateAdapter.moment().utc().subtract(8, 'hours')],
  [RANGE_FILTER.HOURS_24, () => dateAdapter.moment().utc().subtract(1, 'days')],
  [RANGE_FILTER.DAYS_7, () => dateAdapter.moment().utc().subtract(7, 'days')],
  [RANGE_FILTER.DAYS_30, () => dateAdapter.moment().utc().subtract(30, 'days')],
  [RANGE_FILTER.MONTHS_12, () => dateAdapter.moment().utc().subtract(1, 'year')],
]);

export const getTimeRangeWithFormat = (rangeFilter: RANGE_FILTER) => {
  return {
    to: dateAdapter.moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
    from:
      rangeTime.get(rangeFilter)?.().format('YYYY-MM-DDTHH:mm:ss') ??
      dateAdapter.moment().utc().subtract(8, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
  };
};

const content = {
  DEVICE_READINGS_RANGE_HOURS_8: '8 hours',
  DEVICE_READINGS_RANGE_HOURS_24: '24 hours',
  DEVICE_READINGS_RANGE_DAYS_7: '7 days',
  DEVICE_READINGS_RANGE_DAYS_30: '30 days',
  DEVICE_READINGS_RANGE_MONTHS_12: '12 months',
};

interface IChartRangeOption {
  id: RANGE_FILTER;
  name: string;
}

export const chartRangeTypeOptions: IChartRangeOption[] = [
  {
    id: RANGE_FILTER.HOURS_8,
    name: content.DEVICE_READINGS_RANGE_HOURS_8,
  },
  {
    id: RANGE_FILTER.HOURS_24,
    name: content.DEVICE_READINGS_RANGE_HOURS_24,
  },
  {
    id: RANGE_FILTER.DAYS_7,
    name: content.DEVICE_READINGS_RANGE_DAYS_7,
  },
  {
    id: RANGE_FILTER.DAYS_30,
    name: content.DEVICE_READINGS_RANGE_DAYS_30,
  },
  {
    id: RANGE_FILTER.MONTHS_12,
    name: content.DEVICE_READINGS_RANGE_MONTHS_12,
  },
];

export const getTimeFormat = (bucketOption?: TBucketOption | '') => {
  if (bucketOption) {
    switch (bucketOption) {
      case 'minutes':
      case 'hours':
        return 'DD MMM HH:mm';
      case 'days':
      case 'weeks':
        return 'DD MMM';
      case 'months':
        return 'MMM';
      default:
        return 'DD MMM HH:mm';
    }
  }

  return 'DD MMM HH:mm';
};
