import { PageContainer } from '@marlin/shared/ui-page';

import { SustainabilityHeader } from './sustainability-header.component';

export function Sustainability() {
  return (
    <PageContainer prefix="sustainability-page">
      <SustainabilityHeader />
    </PageContainer>
  );
}
