import { RANGE_FILTER, TEventOptions, TToolbarOptions, getTimeFormat } from '@marlin/shared/utils-chart';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { ApexOptions } from 'apexcharts';

interface IApexRangeBarOpts {
  dataPointIndex: number;
  w: {
    config: {
      series: Array<{
        data: Array<{
          x: string;
          y: [number, number];
        }>;
      }>;
    };
  };
}

// RangeBar and Bar chart do not change cursor https://github.com/apexcharts/apexcharts.js/issues/2244
const getBarEvents = (eventOptions: TEventOptions): TEventOptions => ({
  ...eventOptions,
  click: (event, chartContext) => {
    const className = event?.target?.className ?? '';

    if (typeof className === 'string' && className.includes('apexcharts-pan-icon')) {
      chartContext.el.firstChild.firstChild.style.cursor = 'grab';
    }
    if (typeof className === 'string' && className.includes('apexcharts-zoom-icon')) {
      chartContext.el.firstChild.firstChild.style.cursor = 'crosshair';
    }
  },
  scrolled: (event, options) => {
    event.core.el.firstChild.firstChild.style.cursor = 'grabbing';
    eventOptions?.scrolled?.(event, options);
  },
  updated: (chartContext) => {
    if (
      (chartContext?.toolbar?.elPan?.className || '').includes('selected') &&
      !chartContext?.el?.firstChild?.firstChild?.style?.cursor
    ) {
      chartContext.el.firstChild.firstChild.style.cursor = 'grab';
    }
  },
});

export const getRangeBarOptions = (
  chartId: string,
  rangeFilter: RANGE_FILTER,
  toolbarOptions: TToolbarOptions,
  eventOptions: TEventOptions,
  from?: number
): ApexOptions => ({
  chart: {
    type: 'rangeBar',
    id: chartId,
    animations: {
      enabled: false,
    },
    toolbar: toolbarOptions,
    events: getBarEvents(eventOptions),
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    enabled: true,
    x: {
      show: false,
      formatter(val: number | string, opts?: IApexRangeBarOpts): string {
        const isDate = typeof val === 'number' && !opts;

        if (isDate) {
          const date = from && from > val ? from : val;

          return dateAdapter.date(date)?.format(getTimeFormat()) ?? '';
        }

        return opts?.w.config.series[0]?.data[opts?.dataPointIndex]?.x ?? '';
      },
    },
  },
});

export const getBarOptions = (
  chartId: string,
  toolbarOptions: TToolbarOptions,
  eventOptions: TEventOptions,
  columnAmount: number
): ApexOptions => ({
  chart: {
    id: chartId,
    stacked: true,
    animations: {
      enabled: false,
    },
    toolbar: toolbarOptions,
    events: getBarEvents(eventOptions),
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: `${calculateColumnWidth(columnAmount)}px`,
      dataLabels: {
        total: {
          enabled: false,
        },
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
    colors: ['#fff'],
  },
});

const calculateColumnWidth = (columnAmount: number) => {
  if (!columnAmount) {
    return 20;
  }

  const windowWidthWithMargins = window.innerWidth * 0.8;

  const calculatedWidth = Math.round(windowWidthWithMargins / columnAmount);

  return Math.min(Math.max(calculatedWidth, 4), 30);
};
