import { z } from 'zod';

import { TPortfolioRole } from './portfolio-role';

export enum ROLE {
  ADMIN = 'Admin',
  VIEWER = 'Viewer',
  DELETED = 'Deleted',
}

export enum INTERNAL_ROLE {
  SUPPORT = 'Support',
  CSM = 'CSM',
  BUCS = 'BUCS',
}

export type TRole = `${ROLE}`;
export type TInternalRole = `${INTERNAL_ROLE}`;
export type TMultiOrgRole = TRole | TInternalRole;
export type TPortfolioMultiOrgRole = TPortfolioRole | TInternalRole;

export const RoleSchema = z.union([
  z.literal('Support'),
  z.literal('Admin'),
  z.literal('Viewer'),
  z.literal('Deleted'),
  z.literal('CSM'),
  z.literal('BUCS'),
]);
export const MultiOrgRoleSchema = z.union([z.literal('Admin'), z.literal('Viewer'), z.literal('Support')]);

export const PortfolioMultiOrgRoleSchema = z.union([z.literal('Viewer'), z.literal('Support'), z.literal('Manager')]);

export const SupportRoleSchema = z.union([z.literal('Support'), z.literal('CSM'), z.literal('BUCS')]);

export const RolesSchema = z.preprocess((roles: unknown) => {
  const result = z.array(z.string()).min(1).parse(roles);
  return result.filter((role) => RoleSchema.safeParse(role).success);
}, z.array(RoleSchema));

export const MultiOrgRolesSchema = z.preprocess((roles: unknown) => {
  return z.array(z.string()).min(1).parse(roles);
}, z.array(MultiOrgRoleSchema).min(1));

export const PortfolioMultiOrgRolesSchema = z.preprocess((roles: unknown) => {
  return z.array(z.string()).min(1).parse(roles);
}, z.array(PortfolioMultiOrgRoleSchema).min(1));
