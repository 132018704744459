import { useUpdateSettings } from '@marlin/account-data-access-organization';
import { useCurrentUser } from '@marlin/account-data-access-organization';
import { SettingsRadioGroupControl, SettingsSelectControl } from '@marlin/shared/ui-form';
import { Paper } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AppearanceCard } from './appearance-card/appearance-card.component';
import { defaultUsersSettingsValues } from './consts';
import { content } from './content';
import { useStyles } from './settings-appearance.styles';
import { IUnitsOfMeasurementForm } from './types';
import { energyOptions, flowOptions, gasOptions, pressureOptions, temperatureOptions } from './uom-settings/options';
import { TEnergy, TFlow } from './uom-settings/schema';

export const SettingsAppearance = () => {
  const { classes } = useStyles();
  const { data } = useCurrentUser();
  const update = useUpdateSettings();
  const { enqueueSnackbar } = useSnackbar();

  const defaultFormValues: IUnitsOfMeasurementForm = {
    defaultHomePage: data?.settings.defaultHomePage || defaultUsersSettingsValues.defaultHomePage,
    energyUnit: data?.settings.energyUnit || defaultUsersSettingsValues.energyUnit,
    flowUnit: data?.settings.flowUnit || defaultUsersSettingsValues.flowUnit,
    pressureUnit: data?.settings.pressureUnit?.toUpperCase() || defaultUsersSettingsValues.pressureUnit,
    temperatureUnit: data?.settings.temperatureUnit || defaultUsersSettingsValues.temperatureUnit,
    timeFormat: data?.settings.timeFormat || defaultUsersSettingsValues.timeFormat,
    volumeUnit: data?.settings.volumeUnit || defaultUsersSettingsValues.volumeUnit,
  };

  const form = useForm<IUnitsOfMeasurementForm>({
    defaultValues: defaultFormValues,
  });

  const onSubmit = useCallback(
    (data: IUnitsOfMeasurementForm) => {
      update
        .mutateAsync(data)
        .then(() => {
          enqueueSnackbar(content.DEFAULT_UOM_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .catch(() => {
          enqueueSnackbar(content.SOMETHING_WENT_WRONG, {
            variant: 'error',
            preventDuplicate: true,
          });
        });
    },
    [enqueueSnackbar, update]
  );

  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [form, onSubmit]);

  const isDashboardActive = data?.settings.defaultHomePage === 'DASHBOARD';
  const isLocationsActive = data?.settings.defaultHomePage === 'LOCATIONS';

  return (
    <>
      <Paper className={classes.container} data-testid="card">
        <Typography className={classes.title}>{content.DEFAULT_HOME_VIEW}</Typography>
        <Typography className={classes.subtitle}>{content.SELECT_LAYOUT_SUBTITLE}</Typography>
        <div className={classes.cardsContainer}>
          <AppearanceCard variant="DASHBOARD" isActive={isDashboardActive} />
          <AppearanceCard variant="LOCATIONS" isActive={isLocationsActive} />
        </div>
      </Paper>
      <Paper className={classes.container} data-testid="units-of-measurement-card">
        <Typography className={classes.uomTitle}>{content.UNITS_OF_MEASUREMENT}</Typography>
        <FormProvider {...form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <SettingsRadioGroupControl<IUnitsOfMeasurementForm>
                fieldName="temperatureUnit"
                options={temperatureOptions}
                label={content.TEMPERATURE_CONTROL_LABEL}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <SettingsSelectControl<IUnitsOfMeasurementForm, TFlow>
                label={content.FLOW_CONTROL_LABEL}
                options={flowOptions}
                fieldName="flowUnit"
                testId={content.FLOW_CONTROL_LABEL}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <SettingsRadioGroupControl<IUnitsOfMeasurementForm>
                fieldName="pressureUnit"
                options={pressureOptions}
                label={content.PRESSURE_CONTROL_LABEL}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <SettingsSelectControl<IUnitsOfMeasurementForm, TEnergy>
                label={content.ENERGY_CONTROL_LABEL}
                options={energyOptions}
                fieldName="energyUnit"
                testId={content.ENERGY_CONTROL_LABEL}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <SettingsRadioGroupControl<IUnitsOfMeasurementForm>
                fieldName="volumeUnit"
                options={gasOptions}
                label={content.GAS_CONTROL_LABEL}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </Paper>
    </>
  );
};
