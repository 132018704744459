import { DurationModal, durationOptions } from '@marlin/asset/shared/ui/custom-duration-modal';
import { SelectAveragingFunction, SelectDuration } from '@marlin/shared/ui-form';
import { PageHeader } from '@marlin/shared/ui-page';
import {
  AVERAGING_FUNCTION_FILTER,
  RANGE_FILTER,
  TChartType,
  getChartIcon,
  useCustomPeriodModalContextStore,
} from '@marlin/shared/utils-chart';
import BarChartRounded from '@mui/icons-material/BarChartRounded';
import { Box, Button, Modal, SelectChangeEvent, useMediaQuery } from '@mui/material';
import noop from 'lodash/noop';

import { content } from '../../content';
import { useStyles } from './header.styles';

interface IChartHeaderProps {
  range: RANGE_FILTER;
  chartType: TChartType | null;
  handleRefresh: () => void;
  title: string;
  hasNoCharts?: boolean;
  onCustomDurationChange: (option: RANGE_FILTER) => void;
  onRangeFilterChange: (event: SelectChangeEvent<RANGE_FILTER>) => void;
  onAveragingFunctionFilterChange: (event: SelectChangeEvent<AVERAGING_FUNCTION_FILTER>) => void;
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER;
  open?: boolean;
  handleReset: () => void;
}

export const BaseHeader = ({
  range,
  title,
  chartType,
  handleRefresh,
  onRangeFilterChange,
  hasNoCharts,
  onCustomDurationChange,
  onAveragingFunctionFilterChange,
  averagingFunctionFilter,
  open,
  handleReset,
}: IChartHeaderProps) => {
  const { classes, theme } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (hasNoCharts) {
    return <PageHeader icon={<BarChartRounded className={classes.chartIcon} />} prefix="multi-charts" title={title} />;
  }

  const filters = (
    <div className={classes.filters}>
      <SelectDuration
        rangeFilter={range}
        onRangeFilterChange={onRangeFilterChange}
        onRefresh={handleRefresh}
        options={durationOptions}
        customRenderValue={RenderValue}
        fullWidth={isMobile}
      />
      <SelectAveragingFunction
        averagingFunctionFilter={averagingFunctionFilter}
        onAveragingFunctionFilterChange={onAveragingFunctionFilterChange}
        fullWidth={isMobile}
      />
    </div>
  );

  return (
    <>
      <PageHeader
        isTitleInline={!!title}
        prefix="multi-charts"
        title={title}
        icon={chartType ? getChartIcon(chartType) : <BarChartRounded className={classes.chartIcon} />}
        actions={
          <div className={classes.buttons}>
            {!isMobile && filters}
            <Button
              data-testid="analytics-header-reset-button"
              variant="outlined"
              onClick={handleReset}
              className={classes.resetViewButton}
            >
              {content.RESET_VIEW}
            </Button>
          </div>
        }
        bottomActions={isMobile ? filters : undefined}
        transparent
        showIconOnMobile
      />
      <Modal open={!!open} onClose={noop} data-testid="header-section-duration-modal">
        <Box className={classes.modal} data-testid="header-section-duration-modal-body">
          <DurationModal setRangeFilter={onCustomDurationChange} />
        </Box>
      </Modal>
    </>
  );
};

const RenderValue = (id: RANGE_FILTER) => {
  const [fromDate] = useCustomPeriodModalContextStore((store) => store.fromDate);
  const [toDate] = useCustomPeriodModalContextStore((store) => store.toDate);

  if (id === RANGE_FILTER.CUSTOM) {
    return (
      <div>
        {content.CUSTOM_PERIOD} {fromDate?.format('MM/DD/YY')} - {toDate?.format('MM/DD/YY')}
      </div>
    );
  }

  return <div>{durationOptions.find(({ id: currentId }) => currentId === id)?.name}</div>;
};
