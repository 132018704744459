import { invalidateAnalyticsTelemetry, queryKey } from '@marlin/asset/data-access/telemetry';
import { AVERAGING_FUNCTION_FILTER, RANGE_FILTER, isOfChartRangeType } from '@marlin/shared/utils-chart';
import { queryClient } from '@marlin/shared/utils/react-query';
import { SelectChangeEvent } from '@mui/material';
import moment from 'moment/moment';
import { useCallback } from 'react';

import { useDurationDetailsStore } from '../context/duration-details.context';
import { useMultiChartsStore } from '../context/multi-charts.context';
import { useSaveAnalyticsUserData } from './use-save-user-data.hook';

export const useHeader = () => {
  const [range] = useMultiChartsStore((store) => store.rangeFilter.range);
  const [averagingFunctionFilter, setMultiChartsStore] = useMultiChartsStore((store) => store.averagingFunctionFilter);
  const [durationDetails] = useDurationDetailsStore((store) => store);
  const { onAveragingFunctionFilterSave } = useSaveAnalyticsUserData();

  const onRangeFilterChange = useCallback(
    (event: SelectChangeEvent<RANGE_FILTER>) => {
      const newRangeFilter = event.target.value as RANGE_FILTER;

      if (newRangeFilter === RANGE_FILTER.CUSTOM) {
        return;
      }

      if (isOfChartRangeType(newRangeFilter)) {
        Object.keys(durationDetails).forEach((chartId) => {
          durationDetails[chartId].handleZoomChange();
        });

        setMultiChartsStore({
          rangeFilter: {
            currentDate: moment().utc(),
            range: newRangeFilter,
          },
        });
      }
    },
    [durationDetails, setMultiChartsStore]
  );

  const onAveragingFunctionFilterChange = useCallback(
    (event: SelectChangeEvent<AVERAGING_FUNCTION_FILTER>) => {
      const newRangeFilter = event.target.value as AVERAGING_FUNCTION_FILTER;
      setMultiChartsStore({ averagingFunctionFilter: newRangeFilter });
      onAveragingFunctionFilterSave(newRangeFilter);
    },
    [onAveragingFunctionFilterSave, setMultiChartsStore]
  );

  /* TODO: commented to MVP as there is only duration to reset*/
  // const handleReset = () => {
  //   setRangeFilter(RANGE_FILTER.DAYS_7);
  //   resetActiveDatapoints();
  //   onResetView();

  //   queryClient.invalidateQueries(queryKey.ANALYTICS_TELEMETRY());
  // };

  const handleRefresh = () => {
    if (range === RANGE_FILTER.CUSTOM) {
      queryClient.invalidateQueries({ queryKey: queryKey.ANALYTICS_TELEMETRY() });

      return;
    }

    invalidateAnalyticsTelemetry(durationDetails);

    setMultiChartsStore({
      rangeFilter: {
        range,
        currentDate: moment().utc(),
      },
    });
  };

  const onCustomDurationChange = useCallback(
    (option: RANGE_FILTER) => {
      Object.keys(durationDetails).forEach((chartId) => {
        durationDetails[chartId].handleZoomChange();
      });

      setMultiChartsStore({
        rangeFilter: {
          range: option,
          currentDate: moment().utc(),
        },
      });
    },
    [durationDetails, setMultiChartsStore]
  );

  return {
    range,
    handleRefresh,
    onRangeFilterChange,
    onCustomDurationChange,
    onAveragingFunctionFilterChange,
    averagingFunctionFilter,
  };
};
