import { useSaveUserData } from '@marlin/account-data-access-user';
import { AVERAGING_FUNCTION_FILTER } from '@marlin/shared/utils-chart';
import { useCallback } from 'react';

import { analyticsAveragingFunctionFilterUserDataAreaKey, analyticsSelectedSensorsUserDataAreaKey } from '../const';
import { TSelectedGroupsWithDatapoints, latestAnalyticsJsonSchemaVersion } from '../types';

export const useSaveAnalyticsUserData = () => {
  const saveUserData = useSaveUserData();

  const onSelectedSensorsSave = useCallback(
    (selectedSensors: TSelectedGroupsWithDatapoints) => {
      saveUserData.mutate({
        data: JSON.stringify({
          ...selectedSensors,
          version: latestAnalyticsJsonSchemaVersion,
        }),
        area: analyticsSelectedSensorsUserDataAreaKey,
      });
    },
    [saveUserData]
  );

  const onAveragingFunctionFilterSave = useCallback(
    (averagingFunctionFilter: AVERAGING_FUNCTION_FILTER) => {
      saveUserData.mutate({
        data: JSON.stringify({
          averagingFunctionFilter,
          version: latestAnalyticsJsonSchemaVersion,
        }),
        area: analyticsAveragingFunctionFilterUserDataAreaKey,
      });
    },
    [saveUserData]
  );

  return {
    onSelectedSensorsSave,
    onAveragingFunctionFilterSave,
  };
};
