import { invalidateAnalyticsTelemetry, queryKey } from '@marlin/asset/data-access/telemetry';
import { TChartId } from '@marlin/asset/shared/equipment-config';
import { DurationModal, durationOptions } from '@marlin/asset/shared/ui/custom-duration-modal';
import { FullLogo, MarlinTheme } from '@marlin/shared/theme';
import { SelectDuration } from '@marlin/shared/ui-form';
import { SkeletonLoader } from '@marlin/shared/ui-loader';
import { RANGE_FILTER, isOfChartRangeType, useCustomPeriodModalContextStore } from '@marlin/shared/utils-chart';
import { queryClient } from '@marlin/shared/utils/react-query';
import { ZoomInMapRounded, ZoomOutMapRounded } from '@mui/icons-material';
import { Box, Button, Modal, SelectChangeEvent } from '@mui/material';
import noop from 'lodash/noop';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useDurationContext } from './context/context-duration.context';
import { useSelectedDatapointsContext } from './context/selected-datapoints.context';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(16),
    gap: theme.typography.pxToRem(16),
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    minWidth: theme.typography.pxToRem(400),
    '& > span': {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.secondary,
    },
  },
  modal: {
    backgroundColor: theme.palette.background.secondary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.typography.pxToRem(4),
    padding: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.typography.pxToRem(32)})`,
      marginRight: theme.typography.pxToRem(16),
    },
  },
  actionButtons: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: `${theme.typography.pxToRem(8)} 0`,
    gap: theme.typography.pxToRem(8),
  },
  icon: {
    marginLeft: theme.typography.pxToRem(8),
  },

  fullscreenHeader: {
    marginRight: 'auto',
    marginLeft: theme.typography.pxToRem(16),
  },
}));

const logoHeight = 30;

interface IHeader {
  isLoading: boolean;
  toggleFullScreen: () => void;
  isFullScreen: boolean;
}

export const Header = ({ isLoading, isFullScreen, toggleFullScreen }: IHeader) => {
  const { classes } = useStyles();
  const { rangeFilter, details, handleRangeFilterChange } = useDurationContext();
  const { setDefault } = useSelectedDatapointsContext();

  const [open] = useCustomPeriodModalContextStore((store) => store.open);

  const onRangeFilterChange = useCallback(
    (event: SelectChangeEvent<RANGE_FILTER>) => {
      const newRangeFilter = event.target.value;

      if (newRangeFilter === RANGE_FILTER.CUSTOM) {
        return;
      }

      if (isOfChartRangeType(newRangeFilter)) {
        Object.keys(details).forEach((key) => {
          const chartId = key as TChartId;
          details[chartId].handleZoomChange();
        });
        handleRangeFilterChange(newRangeFilter);
      }
    },
    [details, handleRangeFilterChange]
  );

  const onRefresh = useCallback(async () => {
    if (rangeFilter.range === RANGE_FILTER.CUSTOM) {
      queryClient.invalidateQueries({ queryKey: queryKey.ANALYTICS_TELEMETRY() });

      return;
    }

    // when zoom set
    invalidateAnalyticsTelemetry(details);

    handleRangeFilterChange();
  }, [details, handleRangeFilterChange, rangeFilter.range]);

  const onCustomDurationChange = (option: RANGE_FILTER) => {
    Object.keys(details).forEach((key) => {
      const chartId = key as TChartId;
      details[chartId].handleZoomChange();
    });
    handleRangeFilterChange(option);
  };

  const onResetViewClick = useCallback(() => {
    setDefault();

    if (rangeFilter.range !== RANGE_FILTER.HOURS_8) {
      handleRangeFilterChange(RANGE_FILTER.HOURS_8);
    } else {
      Object.keys(details).forEach((key) => {
        const chartId = key as TChartId;
        details[chartId].handleZoomChange();
      });
    }
  }, [details, rangeFilter, setDefault, handleRangeFilterChange]);

  return (
    <div className={classes.headerSection} data-testid="header-section">
      {isFullScreen && <FullLogo height={logoHeight} />}
      <SkeletonLoader loading={isLoading}>
        <div className={classes.duration} data-testid="header-section-duration">
          <SelectDuration
            options={durationOptions}
            rangeFilter={rangeFilter.range}
            onRangeFilterChange={onRangeFilterChange}
            customRenderValue={RenderValue}
            onRefresh={onRefresh}
            fullWidth
          />
        </div>
      </SkeletonLoader>
      <div className={classes.actionButtons}>
        <Button variant="outlined" onClick={onResetViewClick} data-testid="reset-view-button">
          {content.RESET_VIEW}
        </Button>
        {isFullScreen ? (
          <Button variant="contained" onClick={toggleFullScreen} data-testid="exit-full-screen-button">
            {content.EXIT_FULL_SCREEN}
            <ZoomInMapRounded className={classes.icon} />
          </Button>
        ) : (
          <Button variant="outlined" onClick={toggleFullScreen} data-testid="full-screen-button">
            {content.FULL_SCREEN}
            <ZoomOutMapRounded className={classes.icon} />
          </Button>
        )}
        {/*
            TODO: uncomment when CSV download is available
            <Button variant="outlined" className={classes.button}>
              {content.DOWNLOAD_CSV}
            </Button>*/}
      </div>
      <Modal open={open} onClose={noop} data-testid="header-section-duration-modal">
        <Box className={classes.modal} data-testid="header-section-duration-modal-body">
          <DurationModal setRangeFilter={onCustomDurationChange} />
        </Box>
      </Modal>
    </div>
  );
};

const RenderValue = (id: RANGE_FILTER) => {
  const [fromDate] = useCustomPeriodModalContextStore((store) => store.fromDate);
  const [toDate] = useCustomPeriodModalContextStore((store) => store.toDate);

  if (id === RANGE_FILTER.CUSTOM) {
    return (
      <div>
        {content.CUSTOM} {fromDate?.format('MM/DD/YY')} - {toDate?.format('MM/DD/YY')}
      </div>
    );
  }

  return <div>{durationOptions.find(({ id: currentId }) => currentId === id)?.name}</div>;
};
