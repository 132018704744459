import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCallback } from 'react';
import { Node, useReactFlow, useStore, useUpdateNodeInternals } from 'reactflow';

import { content } from '../content';

const getGridSize = (n: number) => {
  return Math.floor(Math.sqrt(n));
};

const calculateNewPosition = (
  node: Node,
  nodes: Node[],
  grid: number,
  i: number,
  j: number
): { x: number; y: number } => {
  const newPosition = {
    x: node.position.x + grid * i,
    y: node.position.y + grid * j,
  };
  const willOverlap = nodes.find(
    (existingNode) => existingNode.position.x === newPosition.x && existingNode.position.y === newPosition.y
  );
  return willOverlap ? calculateNewPosition(node, nodes, grid, i + 1, j) : newPosition;
};

export const useTeeIntersection = () => {
  const { getNodes, setNodes, getIntersectingNodes } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const { enqueueSnackbar } = useSnackbar();
  const grid = useStore((store) => store.snapGrid[0]);

  const getAllOverlappingTees = useCallback(() => {
    const teeNodes = getNodes().filter((node) => node.type === 'TEE');
    return teeNodes
      .flatMap((teeNode) => {
        return getIntersectingNodes(teeNode).filter((node) => node.type === 'TEE');
      })
      .filter((node, index, array) => array.findIndex((node2) => node2.id === node.id) === index);
  }, [getIntersectingNodes, getNodes]);

  const moveTees = useCallback(
    (intersectingTeeNodes: Node[], moveAway = false) => {
      const x = getGridSize(intersectingTeeNodes.length);
      let i = 0,
        j = 0;
      setNodes((nodes) =>
        nodes.map((node) => {
          const teeNode = intersectingTeeNodes.find((tNode) => tNode.id === node.id);
          if (teeNode) {
            if (i++ >= x) {
              i = 0;
              j++;
            }
            const newPosition = calculateNewPosition(node, nodes, grid, i, j);
            return {
              ...node,
              position: newPosition,
            };
          }
          return node;
        })
      );
      intersectingTeeNodes.forEach((teeNode) => updateNodeInternals(teeNode.id));
      enqueueSnackbar(content.OVERLAPPING_TEES, {
        variant: 'info',
        preventDuplicate: true,
      });
    },
    [enqueueSnackbar, grid, setNodes, updateNodeInternals]
  );

  const moveAllOverlappingTees = useCallback(() => {
    const intersectingTeeNodes = getAllOverlappingTees();
    if (intersectingTeeNodes.length) {
      moveTees(intersectingTeeNodes);
    }
  }, [getAllOverlappingTees, moveTees]);

  const moveSingleOverlappingTee = useCallback(
    (teeNode: Node) => {
      const intersectingTeeNodes = getIntersectingNodes(teeNode).filter((node) => node.type === 'TEE');
      if (intersectingTeeNodes.length) {
        moveTees(intersectingTeeNodes);
      }
    },
    [getIntersectingNodes, moveTees]
  );

  return { moveAllOverlappingTees, moveSingleOverlappingTee };
};
