import { MarlinTheme } from '@marlin/shared/theme';
import { PageHeader } from '@marlin/shared/ui-page';
import { Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    '& span': { fontSize: theme.typography.pxToRem(28) },
  },
}));

export const SustainabilityHeader = () => {
  const { classes } = useStyles();
  return (
    <PageHeader
      prefix="sustainability"
      title={content.SUSTAINABILITY_TITLE}
      subtitle={content.SUSTAINABILITY_SUBTITLE}
      icon={
        <Icon className={classes.icon} baseClassName="material-symbols-outlined">
          nest_eco_leaf
        </Icon>
      }
    />
  );
};
