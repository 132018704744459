import { dateAdapter } from '@marlin/shared/utils-common-date';

import { TBucketOption } from '../model';

export const DateFormat = 'YYYY-MM-DDTHH:mm:ss';

export const getDatesForBarChart = (from: string, to: string, bucketOption?: TBucketOption) => {
  const dateFrom = dateAdapter.date(from);
  const dateTo = dateAdapter.date(to);

  if (!dateFrom || !dateTo || !bucketOption) return { dateFrom: from, dateTo: to };

  return {
    dateFrom: getDateFrom(dateFrom, bucketOption)?.utc().format(DateFormat),
    dateTo: getDateTo(dateTo, bucketOption)?.utc().format(DateFormat),
  };
};

export const getBucketSizeForBarChart = (bucketOption?: TBucketOption) => {
  if (!bucketOption) return 0;

  switch (bucketOption) {
    case 'minutes':
      return 1;
    case 'hours':
      return 60;
    case 'days':
      return 1440;
    case 'weeks':
      return 10080;
    case 'months':
      return 43200;
  }
};

export const getDateFrom = (dateFrom: moment.Moment | null, bucketOption: TBucketOption) => {
  if (!dateFrom) return dateFrom;
  const timeUnit = bucketOption.slice(0, -1) as 'minute' | 'hour' | 'day' | 'week' | 'month';

  return dateFrom.clone().startOf(timeUnit);
};

export const getDateTo = (dateTo: moment.Moment | null, bucketOption: TBucketOption) => {
  if (!dateTo) return dateTo;
  const timeUnit = bucketOption.slice(0, -1) as 'minute' | 'hour' | 'day' | 'week' | 'month';

  return dateTo.clone().endOf(timeUnit);
};

export const getDateFromWithAdjustByBucket = (dateFrom: moment.Moment | null, bucketOption: TBucketOption) => {
  if (!dateFrom) return dateFrom;

  const time = bucketOption.slice(0, -1) as 'minute' | 'hour' | 'day' | 'week' | 'month';

  return dateFrom.clone().add(1, time).startOf(time);
};

export const getDateToWithAdjustByBucket = (dateTo: moment.Moment | null, bucketOption: TBucketOption) => {
  if (!dateTo) return dateTo;
  const time = bucketOption.slice(0, -1) as 'minute' | 'hour' | 'day' | 'week' | 'month';

  return dateTo.clone().endOf(time).subtract(1, time);
};
