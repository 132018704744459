import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import { useMemo } from 'react';
import { NodeProps, useReactFlow } from 'reactflow';

import { CommonLocationNode } from './location-node/common-location-node.component';
import { GroupLocationNode } from './location-node/group-location-node.component';

type TNodeData = TGraphAsset['data'] & { label: string };

export const LocationNode = ({ data, id, ...props }: NodeProps<TNodeData>) => {
  const { getNodes } = useReactFlow();
  const nodes = getNodes();
  const { isGroup } = useMemo(() => {
    return {
      isGroup: nodes.filter((n) => n.parentNode === id).length > 0,
    };
  }, [nodes, id]);

  if (isGroup) {
    return <GroupLocationNode {...props} data={data} id={id} />;
  }

  return <CommonLocationNode {...props} id={id} data={data} />;
};
