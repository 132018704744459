import { Node } from 'reactflow';

import { TGraphAsset } from '../schema/cytoscape.schema';
import { cytoToReactFlowPosition } from './node-position-adapters';
const getNodeStyle = (node: TGraphAsset, isParent: boolean) => {
  if (isParent) {
    const width = node.data.nodeTags?.find((tag) => tag.name === 'width')?.value;
    const height = node.data.nodeTags?.find((tag) => tag.name === 'height')?.value;

    if (width && height) {
      return {
        width: parseInt(width),
        height: parseInt(height),
      };
    }
    return {};
  }
  if (node.data.assetType === 'DEVICE') {
    return {
      width: 96,
      height: 96,
    };
  }
  if (node.data.nodeType === 'TEE') {
    return {
      width: 16,
      height: 16,
    };
  }

  return {};
};

const teezIndex = 102;
const nodezIndex = 101;
const equipmentzIndex = 100;

const getzIndex = (node: TGraphAsset, isParent: boolean) => {
  if (node.data.nodeType === 'TEE') {
    return teezIndex;
  }
  if (node.data.assetType === 'EQUIPMENT') {
    return equipmentzIndex;
  }
  if (!isParent) {
    return nodezIndex;
  }
  return undefined;
};

const getNodeSizeTags = (node: Node, isParent: boolean) => {
  if (!isParent) {
    return [];
  }
  return [
    {
      name: 'width',
      value: node.width?.toString() || null,
    },
    {
      name: 'height',
      value: node.height?.toString() || null,
    },
  ];
};

export const cytoToReactFlowNodeAdapterFactory =
  (nodes: TGraphAsset[]) =>
  (node: TGraphAsset): Node => {
    const parentNode = nodes.find((n) => node.data.parent === n.data.id);
    const isParent = nodes.some((n) => n.data.parent === node.data.id);

    return {
      extent: node.data.parent ? ('parent' as const) : undefined,
      id: node.data.id,
      position: cytoToReactFlowPosition(node, parentNode),
      data: {
        ...node.data,
        label: node.data.assetType === 'DEVICE' ? node.data.reading : node.data.name,
      },
      parentNode: node.data.parent,
      type: node.data.assetType || node.data.nodeType,
      expandParent: true,
      zIndex: getzIndex(node, isParent),
      ...getNodeStyle(node, isParent),
      style: isParent ? getNodeStyle(node, isParent) : undefined,
    };
  };

export const reactFlowToCytoNodeAdapter = (node: Node, index: number, nodes: Node[]): TGraphAsset => {
  const isParent = nodes.some((n) => n.data.parent === node.data.id);

  const nodeTags = new Map<string, { name: string; value: string | null }>();

  node.data.nodeTags?.forEach((tag: { name: string; value: string | null }) => {
    nodeTags.set(tag.name, { name: tag.name, value: tag.value });
  });

  nodeTags.set('rfPosition', { name: 'rfPosition', value: `${node.position?.x || null},${node.position?.y || null}` });

  getNodeSizeTags(node, isParent).forEach((tag) => {
    nodeTags.set(tag.name, { name: tag.name, value: tag.value });
  });

  return {
    data: {
      ...node.data,
      nodeTags: Array.from(nodeTags.values()),
    },
    position: {
      x: node.positionAbsolute?.x || 0,
      y: node.positionAbsolute?.y || 0,
    },
  };
};
