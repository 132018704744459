import {
  AercoBoilerEquipmentPayload,
  AercoWaterHeaterEquipmentPayload,
  EQUIPMENT_UPSERT_ERROR,
  OtherEquipmentPayload,
  RegisteredEquipmentPayload,
  SentinelEquipmentPayload,
  TCreateEquipmentType,
  TRegisteredEquipmentResponse,
  useCreateEquipment as useCreateOtherEquipment,
  useCreateRegisteredEquipment,
} from '@marlin/asset/data-access/equipment';
import { EquipmentFormTypes } from '@marlin/shared/ui-form';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { getLogger } from '@marlin/shared/utils/logger';
import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';

import { TUpsertEquipmentForm } from '../form/types';
import { getRegistrationErrorCode } from '../utils';
import { content } from './content';

export interface INewEquipment {
  successfullyCreatedEquipment: boolean;
  loadingCreatedEquipment: boolean;
  onSubmit: (value: TUpsertEquipmentForm) => void;
  goToNextRoute: () => void;
  registrationErrorCode: EQUIPMENT_UPSERT_ERROR | undefined;
}

export const useCreateEquipment = (): INewEquipment => {
  const nextRoute = routes.equipments.list.url();
  const { goTo } = useRouter();
  // state for error required because of setError on form dont persist errors after unsuccessful submit
  const [registrationErrorCode, setRegistrationErrorCode] = useState<EQUIPMENT_UPSERT_ERROR | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const goToNextRoute = useCallback(() => {
    goTo(nextRoute);
  }, [goTo, nextRoute]);

  const onSuccess = () => {
    enqueueSnackbar(content.SUCCESS_MESSAGE, {
      variant: 'success',
      preventDuplicate: true,
    });
  };

  const onRegistrationSuccess = (
    { locationId, locationName }: TRegisteredEquipmentResponse,
    { data: payload }: TCreateEquipmentType
  ) => {
    onSuccess();

    if (locationId !== payload.locationId && locationName) {
      enqueueSnackbar(content.UNIT_MOVED_MESSAGE(locationName), {
        variant: 'success',
        preventDuplicate: true,
      });
    }
  };

  const createEquipmentMutation = useCreateOtherEquipment({ onSuccess });
  const createRegisteredEquipmentMutation = useCreateRegisteredEquipment({ onSuccess: onRegistrationSuccess });

  const onSubmit = useCallback(
    async (newData: TUpsertEquipmentForm) => {
      const data = { ...newData, locationId: newData.location.id };
      setRegistrationErrorCode(undefined);

      const submit = async () => {
        const submitCallback = {
          [EquipmentFormTypes.enum.other]: async () =>
            await createEquipmentMutation.mutateAsync({
              data: OtherEquipmentPayload.parse(data),
            }),
          [EquipmentFormTypes.enum.sentinel]: async () =>
            await createRegisteredEquipmentMutation.mutateAsync({
              data: SentinelEquipmentPayload.parse(data),
            }),
          [EquipmentFormTypes.enum.intellistation]: async () =>
            await createRegisteredEquipmentMutation.mutateAsync({
              data: RegisteredEquipmentPayload.parse(data),
            }),
          [EquipmentFormTypes.enum.aercoBenchmark]: async () =>
            await createRegisteredEquipmentMutation.mutateAsync({
              data: AercoBoilerEquipmentPayload.parse(data),
            }),
          [EquipmentFormTypes.enum.aercoInnovation]: async () =>
            await createRegisteredEquipmentMutation.mutateAsync({
              data: AercoWaterHeaterEquipmentPayload.parse(data),
            }),
        };

        try {
          if (data?.formType && data.formType in submitCallback) {
            const submit = submitCallback[data.formType];

            if (submit) await submit();
            getLogger()?.track('EquipmentCreated', {
              equipmentType: data.formType,
            });
            goToNextRoute();
          }
        } catch (error) {
          if (error instanceof AxiosError && newData.formType === 'sentinel') {
            const errorMessage = error?.response?.data.detail;
            enqueueSnackbar(errorMessage, {
              variant: 'error',
              preventDuplicate: true,
            });
            return;
          }

          const registrationErrorCode = getRegistrationErrorCode(error);

          if (registrationErrorCode) {
            setRegistrationErrorCode(registrationErrorCode);
          } else {
            enqueueSnackbar(content.ERROR_MESSAGE, {
              variant: 'error',
              preventDuplicate: true,
            });
          }
        }
      };
      await submit();
    },
    [createEquipmentMutation]
  );
  const successfullyCreatedEquipment = useMemo(
    () => createEquipmentMutation.isSuccess,
    [createEquipmentMutation.isSuccess]
  );

  const loadingCreatedEquipment = useMemo(() => createEquipmentMutation.isLoading, [createEquipmentMutation.isLoading]);

  return {
    successfullyCreatedEquipment,
    loadingCreatedEquipment,
    goToNextRoute,
    onSubmit,
    registrationErrorCode,
  };
};
