import { TPermission } from '@marlin/shared/utils-permission';
import { INTERNAL_ROLE, ROLE, TMultiOrgRole } from '@marlin/shared/utils-role';

import { adminPermissions } from './admin.permission';
import { supportPermissions } from './support.permission';
import { viewerPermissions } from './viewer.permission';

export const rolePermission: { [key in TMultiOrgRole]: TPermission[] } = {
  [ROLE.ADMIN]: adminPermissions,
  [ROLE.VIEWER]: viewerPermissions,
  [ROLE.DELETED]: viewerPermissions,
  [INTERNAL_ROLE.SUPPORT]: supportPermissions,
  [INTERNAL_ROLE.CSM]: supportPermissions,
  [INTERNAL_ROLE.BUCS]: supportPermissions,
};
