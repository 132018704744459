import { useAssetLinks } from '@marlin/asset/data-access/asset';
import { useMemo } from 'react';

import { content } from '../content';
import { ModalBody } from '../delete-modal.body';
import { useStyles } from '../delete-modal.styles';

export interface IDeregisterEquipmentModalBodyProps {
  assetId: string | undefined;
  deleteAssetName: string | undefined;
}

export const DeregisterEquipmentModalBody = ({ deleteAssetName, assetId }: IDeregisterEquipmentModalBodyProps) => {
  const { classes } = useStyles();
  const assetLinksQuery = useAssetLinks({ assetId: assetId ?? '', enabled: !!assetId });

  const isLoading = useMemo(() => {
    return assetLinksQuery.isLoading || assetLinksQuery.isInitialLoading;
  }, [assetLinksQuery.isLoading, assetLinksQuery.isInitialLoading]);

  return (
    <div data-testid="deregister-modal-body" className={classes.modalBody}>
      <ModalBody
        deleteAssetName={deleteAssetName}
        canDelete
        isLoading={isLoading}
        confirmationMessage={content.DEREGISTER_EQUIPMENT_CONFIRMATION}
      />
    </div>
  );
};
