import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { Node } from 'reactflow';

import { useSnapToGrid } from './use-snap-to-grid.hook';
import { useTeeIntersection } from './use-tee-intersection.hook';

export const useDragDrop = () => {
  const { moveSingleOverlappingTee } = useTeeIntersection();
  const { snapNodeToGrid } = useSnapToGrid();

  const onNodeDragStop = useCallback(
    (event: MouseEvent, node: Node) => {
      if (node.type === 'TEE') {
        moveSingleOverlappingTee(node);
        snapNodeToGrid(node);
      } else {
        snapNodeToGrid(node);
      }
    },
    [moveSingleOverlappingTee, snapNodeToGrid]
  );

  return { onNodeDragStop };
};
