import { getDeviceType, getErrorType } from '@marlin/alert/data-access/alert-action';
import { TWaterUsageDevice, useHomeDashboard } from '@marlin/asset/data-access/home-dashboard';
import { FeatureCard, TYPE } from '@marlin/asset/ui-home-hub-cards';
import { ChartModal, IRequestedTelemetry } from '@marlin/asset/ui/charts';
import { PinnedDeviceList } from '@marlin/asset/ui/pinned-device-list';
import { ToggleButtons } from '@marlin/asset/ui/toggle-buttons-group';
import { TotalizationCard } from '@marlin/asset/ui/totalization';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { TopAlertsCard } from '@marlin/shared/ui/top-open-alerts';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Box } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { EmptyCard } from './components/empty-card/empty-card.component';
import { HealthCard } from './components/health-card/health-card.component';
import { EmptyCard as MobileEmptyCard } from './components/mobile/empty-card/empty-card.component';
import { PerformanceCard } from './components/performance-card/performance-card.component';
import { content } from './content';
import { HomeDashboardLayout } from './home-dashboard-layout.component';

const useStyles = makeStyles()(() => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const HomeDashboardHub = () => {
  const { classes } = useStyles();
  const { goTo } = useRouter();
  const theme = useTheme();
  const [requestedAnalyticsTelemetry, setRequestedAnalyticsTelemetry] = useState<IRequestedTelemetry[]>([]);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data, isLoading } = useHomeDashboard();

  const topOpenAlerts = useMemo(
    () =>
      data?.topOpenAlerts.map((alert) => ({
        alert,
        alertErrorType: getErrorType(alert.alertCause),
        alertDeviceType: getDeviceType(alert.deviceType),
      })),
    [data?.topOpenAlerts]
  );

  const automationsCount = data?.automationsCount;
  const devicesCount = data ? data.health.sensors.deviceCount + data.health.equipment.deviceCount : 0;

  const showFlowMeterDetails = useCallback((totalizationDevices: TWaterUsageDevice[]) => {
    setRequestedAnalyticsTelemetry(
      totalizationDevices.map(({ manufacturerId, deviceName: name, locationName, locationId }) => ({
        manufacturerId,
        name,
        locationName,
        locationId,
        datapoints: ['FlowRate'],
      }))
    );
  }, []);

  const closeFlowMeterDetails = useCallback(() => {
    setRequestedAnalyticsTelemetry([]);
  }, []);

  const navigateToCreateNewDevice = () => {
    goTo(routes.sensors.create.globalPath);
  };

  const navigateToCreateNewAutomation: () => void = () => {
    goTo(routes.automations.create.path);
  };

  if (isMobile) {
    return (
      <>
        <PageContainer>
          <PageHeader title={content.HOME_TITLE} prefix="home-dashboard" actions={<ToggleButtons isMobile />} />
          <HomeDashboardLayout
            isLoading={isLoading}
            performance={
              automationsCount === 0 ? (
                <MobileEmptyCard
                  title={content.PERFORMANCE}
                  subtitle={content.NO_AUTOMATIONS}
                  message={content.USE_DESKTOP_AUTOMATIONS}
                  titleTestId="performance-card-title"
                  subtitleTestId="performance-card-subtitle"
                />
              ) : data?.performance ? (
                <PerformanceCard performance={data.performance} />
              ) : null
            }
            health={
              devicesCount === 0 ? (
                <MobileEmptyCard
                  title={content.HEALTH}
                  subtitle={content.NO_SENSORS}
                  message={content.USE_DESKTOP_SENSORS}
                  titleTestId="health-card-title"
                  subtitleTestId="health-card-subtitle"
                />
              ) : data?.health ? (
                <HealthCard health={data.health} devices={data.devices} />
              ) : null
            }
            topOpenAlerts={
              <TopAlertsCard
                topOpenAlerts={topOpenAlerts}
                devicesCount={devicesCount}
                automationsCount={automationsCount}
              />
            }
            totalization={<TotalizationCard showFlowMeterDetails={showFlowMeterDetails} />}
            pinned={
              <PermissionGuard permissions={[PERMISSIONS.SENSOR_DEVICES]}>
                <PinnedDeviceList isMobile />
              </PermissionGuard>
            }
            isMobile
          />
        </PageContainer>
        <ChartModal
          requestedTelemetry={requestedAnalyticsTelemetry}
          closeChartModal={closeFlowMeterDetails}
          chartType={'flow'}
          title={content.CHART_TITLE_MODAL}
        />
      </>
    );
  }

  return (
    <>
      <PageContainer>
        <Box className={classes.headerWrapper}>
          <div>
            <PageHeader
              prefix="home-dashboard"
              title={content.HOME_TITLE}
              subtitle={content.HOME_SUBTITLE}
              icon={<HomeRoundedIcon />}
            />
          </div>
          <div>
            <ToggleButtons />
          </div>
        </Box>
        <HomeDashboardLayout
          isLoading={isLoading}
          performance={
            automationsCount === 0 ? (
              <EmptyCard
                title={content.PERFORMANCE}
                subtitle={content.NO_AUTOMATIONS}
                btnText={content.CREAATE_AUTOMATION}
                action={navigateToCreateNewAutomation}
                actionPermission={[PERMISSIONS.CREATE_NEW_AUTOMATION, PERMISSIONS.CUSTOM_AUTOMATIONS]}
                titleTestId="performance-card-title"
                subtitleTestId="performance-card-subtitle"
              />
            ) : data?.performance ? (
              <PerformanceCard performance={data.performance} />
            ) : null
          }
          health={
            devicesCount === 0 ? (
              <EmptyCard
                title={content.HEALTH}
                subtitle={content.NO_SENSORS}
                btnText={content.ADD_SENSOR}
                action={navigateToCreateNewDevice}
                actionPermission={[PERMISSIONS.CREATE_NEW_DEVICE, PERMISSIONS.SENSOR_DEVICES]}
                titleTestId="health-card-title"
                subtitleTestId="health-card-subtitle"
              />
            ) : data?.health ? (
              <HealthCard health={data.health} devices={data.devices} />
            ) : null
          }
          systemMap={
            <PermissionGuard permissions={[PERMISSIONS.SYSTEM_MAP]}>
              <FeatureCard type={TYPE.SYSTEM_MAP} devicesCount={devicesCount} />
            </PermissionGuard>
          }
          topOpenAlerts={
            <TopAlertsCard
              topOpenAlerts={topOpenAlerts}
              devicesCount={devicesCount}
              automationsCount={automationsCount}
            />
          }
          analytics={
            <PermissionGuard permissions={[PERMISSIONS.DATA_EXPORT]}>
              <FeatureCard type={TYPE.ANALYTICS} devicesCount={devicesCount} />
            </PermissionGuard>
          }
          totalization={<TotalizationCard showFlowMeterDetails={showFlowMeterDetails} />}
          pinned={
            <PermissionGuard permissions={[PERMISSIONS.SENSOR_DEVICES]}>
              <PinnedDeviceList />
            </PermissionGuard>
          }
        />
      </PageContainer>
      <ChartModal
        requestedTelemetry={requestedAnalyticsTelemetry}
        closeChartModal={closeFlowMeterDetails}
        chartType={'flow'}
        title={content.CHART_TITLE_MODAL}
      />
    </>
  );
};
