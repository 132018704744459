import { MarlinTheme } from '@marlin/shared/theme';
import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { maxLabelLength } from '../../const';
import { calculateCeilSnappedValue } from '../../use-snap-to-grid.hook';
import { useCommonStyles } from '../common.styles';
import { NodeTooltip } from '../utils/node-tooltip.component';

interface INodeStyleProps {
  nodeHeight?: number | null;
  nodeWidth?: number | null;
}

export const useStyles = makeStyles<INodeStyleProps>()((theme: MarlinTheme, { nodeWidth, nodeHeight }) => ({
  location: {
    backgroundColor: theme.palette.systemMap.location,
    borderStyle: 'dashed',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
  },
  emptyLocation: {
    width: nodeWidth ? calculateCeilSnappedValue(nodeWidth) : 'auto',
    height: nodeHeight ? calculateCeilSnappedValue(nodeHeight) : 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type TNodeData = TGraphAsset['data'] & { label: string };

export const CommonLocationNode = ({ data, id }: NodeProps<TNodeData>) => {
  const { getNode } = useReactFlow();
  const node = getNode(id);

  const { classes, cx } = useStyles({ nodeWidth: node?.width, nodeHeight: node?.height });
  const { classes: commonClasses } = useCommonStyles();

  const sliceName = !!(data.name && data.name?.length > maxLabelLength);

  return (
    <NodeTooltip text={data.name || ''} when={sliceName}>
      <div
        className={cx(commonClasses.node, classes.location, classes.emptyLocation)}
        data-testid={`location-node-${id}`}
      >
        <div className={commonClasses.nodeContent}>
          <span className={commonClasses.nodeLabel} data-testid={`location-node-${id}-label`}>
            {sliceName ? `${data?.name?.slice(0, maxLabelLength)}...` : data.name}
          </span>
          <LocationOnRoundedIcon />
        </div>
        <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
        <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
        <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
        <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
      </div>
    </NodeTooltip>
  );
};
