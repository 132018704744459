import { MarlinTheme } from '@marlin/shared/theme';
import { MODEL } from '@marlin/shared/utils/zod';
import { NodeProps } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { maxLabelLength } from '../../const';
import { useCommonStyles } from '../common.styles';
import { NodeTooltip } from '../utils/node-tooltip.component';
import { BaseHeader } from './components/base-header.component';
import { MetadataGrid } from './components/metadata-grid.component';
import { SanitizationHeader } from './components/sanitization-header.component';
import { StartupHeader } from './components/startup-header.component';
import { RegisteredEquipmentContext } from './hooks/use-registered-equipment-context';
import { useRegisteredEquipment } from './hooks/use-registered-equipment.hook';
import { TNodeData, TStatus } from './types';
import { getBackgroundByEquipmentStatus, getBorderColorByEquipmentStatus, getEquipmentIcon } from './utils/utils';

interface IStylesProps {
  status?: TStatus;
}

const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { status }) => ({
  name: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '160%',
    '& > span': {
      height: theme.typography.pxToRem(26),
      color: getBorderColorByEquipmentStatus(status, theme),
      marginLeft: theme.typography.pxToRem(8),
    },
    '& > svg': {
      height: theme.typography.pxToRem(26),
      color: getBorderColorByEquipmentStatus(status, theme),
    },
  },
  header: {
    backgroundColor: getBackgroundByEquipmentStatus(status, theme),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(2),
    padding: theme.typography.pxToRem(8),
    border: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
  },

  headerDefault: {
    backgroundColor: getBackgroundByEquipmentStatus(status, theme),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(2),
    padding: theme.typography.pxToRem(8),
    border: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderRadius: `0 0 ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)}`,
  },
  equipment: {
    padding: 0,
  },
  metadataWrapper: {
    borderRight: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderLeft: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderBottom: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderRadius: `0 0 ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)}`,
    overflow: 'hidden',
  },
}));

export const RegisteredEquipmentNodeDetailed = ({ data, id }: NodeProps<TNodeData>) => {
  const registeredEquipment = useRegisteredEquipment(data);
  const { headerStatus, modeValue } = registeredEquipment;
  const { classes, cx } = useStyles({ status: headerStatus });
  const { classes: commonClasses } = useCommonStyles();

  const isModeEnabled = modeValue === 'startup' || modeValue === 'sanitize';
  const sliceName = !!data?.name && data?.name?.length > maxLabelLength;

  return (
    <RegisteredEquipmentContext.Provider value={registeredEquipment}>
      {modeValue === 'startup' && (
        <StartupHeader lastReadingValues={data?.lastReadingValues || []} id={data.linkedId || ''} />
      )}
      {modeValue === 'sanitize' && (
        <SanitizationHeader
          lastReadingValues={data?.lastReadingValues || []}
          variant="default"
          id={data.linkedId || ''}
        />
      )}
      <div className={cx(commonClasses.node, classes.equipment)} data-testid={`equipment-node-${id}`}>
        <BaseHeader
          status={headerStatus}
          variant="default"
          isModeEnabled={isModeEnabled}
          equipmentId={data.linkedId || ''}
          testid={id}
          isDetailed={true}
        >
          <NodeTooltip text={data.name || ''} when={sliceName}>
            <div className={classes.name}>
              {sliceName ? `${data?.name?.slice(0, maxLabelLength)}...` : data?.name}{' '}
              {getEquipmentIcon(data?.metadata?.tags?.model as MODEL)}
            </div>
          </NodeTooltip>
        </BaseHeader>
        <div className={classes.metadataWrapper}>
          {data?.metadata?.layout && (
            <MetadataGrid
              metadataLayout={data?.metadata?.layout}
              metadataGroups={data?.metadata?.metadataGroups || []}
              lastReadingValues={data?.lastReadingValues || []}
              nodeId={id}
              isDetailed={true}
            />
          )}
        </div>
      </div>
    </RegisteredEquipmentContext.Provider>
  );
};
