import { useMarlinPermissions, useMarlinTiers } from '@marlin/account-data-access-organization';
import { TeaserModal, TeaserModalProvider } from '@marlin/account/feature/teaser';
import { DeviceDrawer, DeviceDrawerControls } from '@marlin/asset/feature/device-drawer';
import { environment } from '@marlin/environment';
import { withTheme } from '@marlin/shared/theme';
import { theme } from '@marlin/shared/theme/marlin';
import { Loader } from '@marlin/shared/ui-loader';
import { Modal } from '@marlin/shared/ui-modal';
import { useAuth } from '@marlin/shared/utils-auth';
import { FeatureFlagsContextProvider } from '@marlin/shared/utils-common-feature-flags-context';
import { ModalProvider } from '@marlin/shared/utils-common-modal-context';
import { PERMISSIONS, PermissionGuard, PermissionProvider } from '@marlin/shared/utils-permission';
import { queryClient } from '@marlin/shared/utils/react-query';
import { QueryClientProvider, useIsFetching } from '@marlin/shared/utils/react-query';
import { DeviceDrawerProvider } from '@marlin/shared/utils/sensor-provider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ComponentType, PropsWithChildren, StrictMode, Suspense, useEffect } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { hideAppLoader } from '../app-loader';
import { withRealtimeCommunication, withSnackbar } from './routed-providers';
import { AppRoutes } from './routes.component';

const withSuspense =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element =>
    (
      <Suspense fallback={<Loader />}>
        <Cmp {...props} />
      </Suspense>
    );

const withStrictMode =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element =>
    (
      <StrictMode>
        <Cmp {...props} />
      </StrictMode>
    );

export const withQueryClient =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element =>
    (
      <QueryClientProvider client={queryClient}>
        <Cmp {...props} />
      </QueryClientProvider>
    );

export const withAuth =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    const { authorised } = useAuth();

    if (!authorised) {
      return <Loader />;
    }

    return <Cmp {...props} />;
  };

const withPermissions =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    const { data: marlinData } = useMarlinPermissions();
    const { data: tierData } = useMarlinTiers();
    const permissions = [...marlinData.permissions, ...tierData.permissions];

    return (
      <PermissionProvider permissions={permissions}>
        <Cmp {...props} />
      </PermissionProvider>
    );
  };

export const withLocalizationProvider =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Cmp {...props} />
      </LocalizationProvider>
    );
  };

export const withModalProvider =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    return (
      <ModalProvider>
        <Cmp {...props} />
        <Modal />
      </ModalProvider>
    );
  };
export const withTeaserModalProvider =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    return (
      <TeaserModalProvider>
        <Cmp {...props} />
        <TeaserModal />
      </TeaserModalProvider>
    );
  };

export const withDeviceDrawerProvider =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    return (
      <DeviceDrawerProvider>
        <Cmp {...props} />
        <PermissionGuard permissions={[PERMISSIONS.MULTI_DATA_CHARTS]}>
          <DeviceDrawer controls={<DeviceDrawerControls />}></DeviceDrawer>
        </PermissionGuard>
      </DeviceDrawerProvider>
    );
  };

export const withFeatureFlags =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    return (
      <FeatureFlagsContextProvider featureFlags={environment.module.features}>
        <Cmp {...props} />
      </FeatureFlagsContextProvider>
    );
  };

const useHideAppLoader = () => {
  const { authorised } = useAuth();
  const loadingQueries = useIsFetching();

  useEffect(() => {
    if (authorised && loadingQueries <= 0) {
      hideAppLoader();
    }
  }, [authorised, loadingQueries]);
};

const AppContent = withSnackbar(
  withModalProvider(withTeaserModalProvider(withRealtimeCommunication(withDeviceDrawerProvider(AppRoutes))))
);
const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<AppContent />} />));

export function App() {
  useHideAppLoader();
  return <RouterProvider router={router} />;
}

export default withStrictMode(
  withLocalizationProvider(
    withSuspense(withTheme(withFeatureFlags(withQueryClient(withAuth(withPermissions(App)))), theme))
  )
);
