import { createDateString, formatDate } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { ApexOptions } from 'apexcharts';
import { Data, PlotType } from 'plotly.js';
import { z } from 'zod';

export const chartDisplayType = z.union([
  z.literal('line'),
  z.literal('area'),
  z.literal('bar'),
  z.literal('pie'),
  z.literal('donut'),
  z.literal('radialBar'),
  z.literal('scatter'),
  z.literal('bubble'),
  z.literal('heatmap'),
  z.literal('candlestick'),
  z.literal('boxPlot'),
  z.literal('radar'),
  z.literal('polarArea'),
  z.literal('rangeBar'),
  z.literal('rangeArea'),
  z.literal('treemap'),
]);

export type TChartDisplayType = z.infer<typeof chartDisplayType>;

const chartDisplayTypeToPlotly: Record<TChartDisplayType, PlotType> = {
  line: 'scatter',
  area: 'scatter',
  bar: 'bar',
  pie: 'pie',
  donut: 'pie',
  radialBar: 'scatter',
  scatter: 'scatter',
  bubble: 'scatter',
  heatmap: 'heatmap',
  candlestick: 'candlestick',
  boxPlot: 'box',
  radar: 'scatter',
  polarArea: 'scatter',
  rangeBar: 'bar',
  rangeArea: 'scatter',
  treemap: 'scatter',
};

export interface IChartData {
  x: number;
  y: number | null;
}

export const chartDataSchema = z.object({
  x: z.number(),
  y: z.number().nullable(),
});

export interface IThresholdsSeriesNullable {
  x: number;
  y: number | [number | null, number | null] | null;
}

export const chartSeriesSchema = z.object({
  name: z.string(),
  id: z.string(),
  data: z.array(chartDataSchema),
  color: z.string(),
  uom: z.union([z.string(), z.null()]),
  type: chartDisplayType,
});

export const plotlyAdapterSchema = z.array(chartSeriesSchema).transform((series): Partial<Data>[] => {
  return series.map(
    (s): Partial<Data> => ({
      x: s.data.map((point) => formatDate(createDateString(new Date(point.x)))),
      y: s.data.map((point) => point.y),
      customdata: [s.uom],
      type: chartDisplayTypeToPlotly[s.type] || 'scatter',
      name: s.name,
      line: { color: s.color },
      marker: { color: s.color, size: 2 },
      hoverinfo: 'none',
      mode: 'lines+markers' as const,
    })
  );
});

export interface IChartSeries {
  name: string;
  id: string;
  data: IChartData[];
  color: string;
  uom: TUnitOfMeasure | null;
  type: TChartDisplayType;
}

export interface IThresholdsSeries {
  x: number;
  y: number | null | [number | null, number | null];
}

export interface IApexAxisChartThresholdSeries extends Omit<ApexOptions['series'], 'data'> {
  name: string;
  data: IThresholdsSeries[];
  alias: string;
  uom: TUnitOfMeasure | null;
  type: TChartDisplayType;
  color: string;
}

export type TToolbarOptions = NonNullable<ApexOptions['chart']>['toolbar'];
export type TEventOptions = NonNullable<ApexOptions['chart']>['events'];

const chartTypeValues = ['temperature', 'pressure', 'onOff', 'flow', 'leak', 'custom'] as const;
export const ChartType = z.enum(chartTypeValues);
export type TChartType = z.infer<typeof ChartType>;
