import { RANGE_FILTER, TBucketOption } from '@marlin/shared/utils-chart';

export const minutesIn8Hours = 8 * 60;
export const minutesIn24Hours = 24 * 60;
export const minutesIn7Days = 7 * minutesIn24Hours;
export const minutesIn90Days = 90 * minutesIn24Hours;
export const minutesIn365Days = 365 * minutesIn24Hours;

export function onZoomChange(
  bucketOption: TBucketOption,
  setBucketOption: (bucketOption: TBucketOption) => void,
  dateDifferenceInMinutes: number
) {
  if (!isBucketOptionValid(bucketOption, RANGE_FILTER.CUSTOM, dateDifferenceInMinutes, true)) {
    const newBucketOption = getNewBucketOption(dateDifferenceInMinutes, bucketOption);

    setBucketOption(newBucketOption);
  }
}

export const onRangeFilterChange = (
  rangeFilter: RANGE_FILTER,
  bucketOption: TBucketOption | '',
  setBucketOption: (bucketOption: TBucketOption) => void
) => {
  if (bucketOption && isBucketOptionWithRangeFilterValid(bucketOption, rangeFilter)) return;

  switch (rangeFilter) {
    case RANGE_FILTER.HOURS_8:
      setBucketOption('minutes');
      break;
    case RANGE_FILTER.HOURS_24:
    case RANGE_FILTER.DAYS_7:
      setBucketOption('hours');
      break;
    case RANGE_FILTER.DAYS_30:
    case RANGE_FILTER.MONTHS_12:
      setBucketOption('days');
      break;
    default:
      setBucketOption('days');
      break;
  }
};

const isBucketOptionWithRangeFilterValid = (bucketOption: TBucketOption, rangeFilter: RANGE_FILTER) => {
  switch (bucketOption) {
    case 'minutes':
      return rangeFilter === RANGE_FILTER.HOURS_8;
    case 'hours':
      return [RANGE_FILTER.HOURS_8, RANGE_FILTER.HOURS_24, RANGE_FILTER.DAYS_7].includes(rangeFilter);
    case 'days':
      return [RANGE_FILTER.DAYS_7, RANGE_FILTER.DAYS_30, RANGE_FILTER.MONTHS_12].includes(rangeFilter);
    case 'weeks':
      return rangeFilter === RANGE_FILTER.MONTHS_12;
    case 'months':
      return rangeFilter === RANGE_FILTER.MONTHS_12;
    default:
      return false;
  }
};

export const isBucketOptionValid = (
  bucketOption: TBucketOption,
  rangeFilter: RANGE_FILTER,
  dateDifferenceInMinutes: number,
  isZoomed: boolean
) => {
  const dateDifferenceInMinutesWithMargin = getDateDifferenceInMinutesWithMargin(dateDifferenceInMinutes, bucketOption);

  if (rangeFilter === RANGE_FILTER.CUSTOM || isZoomed) {
    switch (bucketOption) {
      case 'minutes':
        return dateDifferenceInMinutesWithMargin <= minutesIn8Hours;
      case 'hours':
        // Add buffer to hours condition so after adjusting zoom to additional hours it won't switch to 'days'
        return (
          dateDifferenceInMinutesWithMargin <= minutesIn7Days + minutesIn8Hours &&
          dateDifferenceInMinutes >= minutesIn8Hours
        );
      case 'days':
        return dateDifferenceInMinutesWithMargin >= minutesIn24Hours * 3 && dateDifferenceInMinutes <= minutesIn365Days;
      case 'weeks':
        return dateDifferenceInMinutesWithMargin >= minutesIn90Days;
      case 'months':
        return dateDifferenceInMinutesWithMargin >= minutesIn90Days;
      default:
        return false;
    }
  }

  return isBucketOptionWithRangeFilterValid(bucketOption, rangeFilter);
};

export const getNewBucketOption = (dateDifferenceInMinutes: number, previousBucketOption?: TBucketOption) => {
  const dateDifferenceInMinutesWithMargin = getDateDifferenceInMinutesWithMargin(
    dateDifferenceInMinutes,
    previousBucketOption
  );

  if (dateDifferenceInMinutesWithMargin <= minutesIn8Hours) {
    return 'minutes';
  } else if (dateDifferenceInMinutesWithMargin <= minutesIn7Days) {
    return 'hours';
  } else if (dateDifferenceInMinutesWithMargin <= minutesIn365Days) {
    return 'days';
  } else if (dateDifferenceInMinutesWithMargin <= minutesIn90Days) {
    return 'weeks';
  }

  return 'months';
};

const getDateDifferenceInMinutesWithMargin = (dateDifferenceInMinutes: number, bucketOption?: TBucketOption) => {
  const marginMap = new Map<TBucketOption, number>([
    ['minutes', 1],
    ['hours', 60],
    ['days', minutesIn24Hours],
    ['weeks', minutesIn7Days],
    ['months', minutesIn24Hours * 30],
  ]);
  const margin = bucketOption ? marginMap.get(bucketOption) || 0 : 0;

  return dateDifferenceInMinutes + margin + 1;
};
