import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useDeleteNode } from '@marlin/system-map/data-access/system-map';
import { useCallback } from 'react';
import { getConnectedEdges, useReactFlow } from 'reactflow';

import { content } from './content';

const maxAllowedEdges = 4;

export const useDeleteTeeContextMenu = () => {
  const deleteNodeMutation = useDeleteNode();
  const { enqueueSnackbar } = useSnackbar();
  const reactFlow = useReactFlow();

  const deleteTee = useCallback(
    async (id: string) => {
      const node = reactFlow.getNode(id);
      if (!node) {
        return;
      }
      const connectedEdgesCount = getConnectedEdges([node], reactFlow.getEdges()).length;

      if (connectedEdgesCount >= maxAllowedEdges) {
        enqueueSnackbar(content.DELETE_TEE_REMOVE_EXISTING_FLOW_LINKS_ERROR_MESSAGE(connectedEdgesCount - 2), {
          variant: 'error',
          preventDuplicate: true,
        });

        return;
      }

      if (connectedEdgesCount >= 3) {
        enqueueSnackbar(content.DELETE_TEE_REMOVE_EXISTING_FLOW_LINK_ERROR_MESSAGE, {
          variant: 'error',
          preventDuplicate: true,
        });

        return;
      }

      try {
        await deleteNodeMutation.mutateAsync({ flowLinkId: node?.id || '' });
        enqueueSnackbar(content.DELETE_TEE_SUCCESS_MESSAGE, {
          variant: 'success',
          preventDuplicate: true,
        });
      } catch (e) {
        enqueueSnackbar(content.DELETE_TEE_ERROR_MESSAGE, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [reactFlow, deleteNodeMutation, enqueueSnackbar]
  );

  return {
    deleteTee,
  };
};
