import { useGetOrganizationDetails } from '@marlin/account-data-access-organization';
import { useAssetLinks } from '@marlin/asset/data-access/asset';
import {
  DEVICE_TYPE,
  IDevice,
  TSubtype,
  TSubtypesType,
  useDeviceSubtypes,
  useGetManufacturerInfo,
} from '@marlin/asset/data-access/device';
import { AttachmentValidation, extractAtachementLink } from '@marlin/asset/shared/ui/attachment-validation';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { DeviceTypeControl } from '@marlin/shared/ui-form';
import { FormField, Input, Select, SingleDatePicker, ToggleButtons } from '@marlin/shared/ui-form-common';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { Paper } from '@marlin/shared/ui-page';
import {
  TDateString,
  createDateString,
  defaultDateTime,
  formatDate,
  formatTimezone,
  isBefore,
} from '@marlin/shared/utils-common-date';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { CommissionDateModalBody } from './commision-date-modal/commission-date-modal-body.component';
import { CommissionDateModalFooter } from './commision-date-modal/commission-date-modal-footer.component';
import { CommissionDateModalTitle } from './commision-date-modal/commission-date-modal-title.component';
import { meterTypeOptions } from './constants';
import { content } from './content';
import { MeterModelControl } from './meter-model-control/meter-model-control.component';
import { useStyles } from './upsert-device.component.styles';
import { IUpsertDeviceForm, useUpsertDeviceForm } from './use-upsert-device-form.hook';

interface IUpsertDeviceProps {
  defaultValues?: IDevice;
  isEdit?: boolean;
  disableSubmit: boolean;
  onSubmit: (value: IDevice) => void;
  onCancel: () => void;
}

const defaultDebounceTimeInMs = 800;

const manufacturerIdError = { type: 'custom', message: content.MANUFACTURER_ID_IS_NOT_RECOGNIZED };

const UpsertDevice = ({ defaultValues, isEdit = false, disableSubmit, onSubmit, onCancel }: IUpsertDeviceProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { classes } = useStyles();
  const deviceId = defaultValues?.id;
  const [isInitialPulseMeterSet, setIsInitialPulseMeterSet] = useState(false);
  const { data } = useAssetLinks({ assetId: deviceId ?? '', enabled: !!deviceId && !!isEdit });
  const deviceAttachment = extractAtachementLink(data?.links || []);
  const { modalDispatch } = useContext(ModalContext);

  const [debouncedManufacturerId, setDebouncedManufacturerId] = useState<string>('');

  const { form, locationsList } = useUpsertDeviceForm(defaultValues);

  const [meterType, setMeterType] = useState<TSubtypesType>(defaultValues?.meterType ?? 'Water');
  const [isCompound, setIsCompound] = useState<boolean>(defaultValues?.isCompound ?? false);
  const [isCommissionDateValid, setIsCommissionDateValid] = useState<boolean>(true);
  const deviceType = form.watch('deviceType');
  const pulseMeterTypeId = form.watch('pulseMeterTypeId');
  const pulseMeterTypeIds = form.watch('pulseMeterTypeIds');
  const signalReceiverType = form.watch('signalReceiverType');
  const subtypesParams = useMemo(
    () => ({ enabled: !!meterType && deviceType === DEVICE_TYPE.PULSE_METER, params: { meterType, isCompound } }),
    [meterType, deviceType, isCompound]
  );
  const { data: subtypes } = useDeviceSubtypes(subtypesParams);
  const { data: orgDetails } = useGetOrganizationDetails();

  const timezone = useMemo(() => formatTimezone(orgDetails?.timeZone ?? ''), [orgDetails?.timeZone]);

  const commissionDateValue = form.watch('commissionDate') as TDateString;

  const meterTypeToggleOptions = useMemo(
    () =>
      meterTypeOptions.map((option) => ({
        ...option,
        disabled: option.value === 'Gas' && isCompound,
      })),
    [isCompound]
  );

  const isLocationFieldDirty = form.formState.dirtyFields.locationId;

  const isMoreThan2Characters = debouncedManufacturerId.length > 2;

  const {
    data: manufacturerInfo,
    isSuccess: isManufacturerInfoSuccess,
    isError: isManufacturerInfoError,
    isLoading: isManufacturerDataLoading,
    isFetching: isManufacturerInfoFetching,
  } = useGetManufacturerInfo({
    manufacturerId: debouncedManufacturerId,
    enabled: !isEdit && isMoreThan2Characters,
  });

  const debounceManufacturerId = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedManufacturerId(value);
      }, defaultDebounceTimeInMs),
    []
  );

  const submitForm = useCallback(
    (values: IUpsertDeviceForm) => {
      const { pulseMeterManufacturer, pulseMeterModel, ...device } = values;

      const commissionDate = device.commissionDate ?? undefined;

      return onSubmit({
        ...device,
        commissionDate,
        pulseMeterTypeId: isCompound
          ? undefined
          : device.pulseMeterTypeIds?.length
          ? device.pulseMeterTypeIds[0].pulseMeterTypeId
          : undefined,
      });
    },
    [isCompound, onSubmit]
  );

  const openCommissionDateModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <CommissionDateModalTitle />,
        body: <CommissionDateModalBody />,
        footer: (
          <CommissionDateModalFooter
            onCancel={() => {
              setIsInitialPulseMeterSet(false);
              form.setValue('pulseMeterTypeId', defaultValues?.pulseMeterTypeId ?? '');
              form.setValue('pulseMeterTypeIds', defaultValues?.pulseMeterTypeIds ?? []);
              form.setValue('pulseMeterModel', '');
              form.setValue('pulseMeterManufacturer', '');
              setMeterType(defaultValues?.meterType ?? 'Water');
              modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });
            }}
            onConfirm={async () => {
              form.setValue('commissionDate', createDateString(new Date()));
              modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });
              return form.handleSubmit(submitForm)();
            }}
          />
        ),
      },
    });
  }, [
    defaultValues?.meterType,
    defaultValues?.pulseMeterTypeId,
    defaultValues?.pulseMeterTypeIds,
    form,
    modalDispatch,
    submitForm,
  ]);

  useEffect(() => {
    if (isManufacturerInfoSuccess) {
      form.setValue('manufacturer', manufacturerInfo?.manufacturer);
      form.setValue('model', manufacturerInfo?.model);
      if (manufacturerInfo?.deviceChildren && manufacturerInfo?.deviceChildren?.length > 0) {
        setIsCompound(true);
        form.setValue('deviceType', DEVICE_TYPE.PULSE_METER);
        form.setValue('signalReceiverType', content.COMPOUND_PULSE_COUNTER);
      } else {
        form.setValue('deviceType', manufacturerInfo?.deviceType ?? DEVICE_TYPE.UNKNOWN);
        setIsCompound(false);
      }
      form.trigger('manufacturerId');
    }
    if (isManufacturerInfoError && !isEdit) {
      form.setValue('manufacturer', '');
      form.setValue('model', '');
      form.setValue('signalReceiverType', undefined);
      form.resetField('deviceType');
      setIsCompound(false);
    }
  }, [
    form,
    isManufacturerInfoSuccess,
    manufacturerInfo?.manufacturer,
    manufacturerInfo?.deviceType,
    manufacturerInfo?.model,
    isManufacturerInfoError,
    isEdit,
    manufacturerInfo?.deviceChildren?.length,
    manufacturerInfo,
  ]);

  useEffect(() => {
    if (!form.formState.errors.manufacturerId && isManufacturerInfoError) {
      form.setError('manufacturerId', manufacturerIdError);
    }
  }, [form, form.formState.errors.manufacturerId, isManufacturerInfoError]);

  useEffect(() => {
    if (
      commissionDateValue &&
      defaultValues?.assignmentDate &&
      isBefore(createDateString(commissionDateValue), createDateString(defaultValues?.assignmentDate))
    ) {
      form.setError('commissionDate', {
        type: 'custom',
        message: content.INVALID_COMMISION_DATE(
          formatDate(createDateString(defaultValues?.assignmentDate), defaultDateTime)
        ),
      });
      setIsCommissionDateValid(false);
      return;
    }
    setIsCommissionDateValid(true);
    form.trigger('commissionDate');
  }, [defaultValues?.assignmentDate, commissionDateValue, form]);

  useEffect(() => {
    if (
      !isInitialPulseMeterSet &&
      isEdit &&
      (defaultValues?.pulseMeterTypeId || !!defaultValues?.pulseMeterTypeIds?.length) &&
      subtypes
    ) {
      const findSubtype = (pulseId?: string | null) => subtypes?.subtypes.find(({ id }: TSubtype) => id === pulseId);
      const model = isCompound
        ? findSubtype(defaultValues?.pulseMeterTypeIds?.[0]?.pulseMeterTypeId)
        : findSubtype(defaultValues?.pulseMeterTypeId);

      if (model) {
        form.setValue('pulseMeterManufacturer', model.manufacturer);
        form.setValue('pulseMeterModel', model.model);
      }

      setIsInitialPulseMeterSet(() => true);
    }
  }, [
    isInitialPulseMeterSet,
    defaultValues?.pulseMeterTypeId,
    form,
    isEdit,
    subtypes,
    defaultValues?.pulseMeterTypeIds,
    isCompound,
  ]);

  const clearPulseMeter = () => {
    form.setValue('pulseMeterManufacturer', '');
    form.setValue('pulseMeterModel', '');
    form.setValue('pulseMeterTypeIds', []);
    form.setValue('pulseMeterTypeId', '');
  };

  const onManufacturerIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue('manufacturerId', event.target.value);
    debounceManufacturerId(event.target.value);
    clearPulseMeter();
  };

  const handleSubmit = () => {
    const pulseMeterChanged = isCompound
      ? !isEqual(pulseMeterTypeIds, defaultValues?.pulseMeterTypeIds)
      : pulseMeterTypeId !== defaultValues?.pulseMeterTypeId;

    if (isEdit && pulseMeterChanged) {
      openCommissionDateModal();
      return;
    }

    return form.handleSubmit(submitForm)();
  };

  const childrenManufacturerIds = useMemo(() => {
    if (!isCompound) {
      return [];
    }
    if (isEdit) {
      return defaultValues?.devices?.map(({ manufacturerId }) => manufacturerId) || [];
    }
    return manufacturerInfo?.deviceChildren?.map(({ manufacturerId }) => manufacturerId) || [];
  }, [defaultValues?.devices, isCompound, isEdit, manufacturerInfo?.deviceChildren]);

  return (
    <Paper className={classes.formContainer}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className={classes.formSection}>{content.SENSOR_DETAILS}</div>
          <FormField<IUpsertDeviceForm> fieldName="name">
            {(props) => <Input className={classes.input} label={content.SENSOR_NAME_LABEL} required {...props} />}
          </FormField>
          <FormField<IUpsertDeviceForm> fieldName="locationId">
            {(props) => (
              <div className={!!deviceId && isEdit && isLocationFieldDirty ? '' : classes.selectWrapper}>
                <Select
                  {...props}
                  prefix="locationId"
                  label={content.LOCATION_LABEL}
                  data={locationsList || []}
                  className={classes.select}
                  required
                  value={props.value || ''}
                  onChange={(e: unknown) => {
                    props.onChange && props.onChange(e);
                  }}
                />
              </div>
            )}
          </FormField>
          <div className={classes.formSection}>
            <Typography fontWeight="bold">{content.REGISTER_CONNECT}</Typography>
            <Typography className={classes.formHelperText}>{content.CONNECT_TO_SENSOR}</Typography>
          </div>
          <FormField<IUpsertDeviceForm> fieldName="manufacturerId">
            {(props) => {
              // NOTE: Validation schema clears error, to not show glitching error message lets based on info query status
              const error = isManufacturerInfoError ? manufacturerIdError : props.error;

              return (
                <Input
                  {...props}
                  className={classes.input}
                  label={content.MANUFACTURER_ID_LABEL}
                  required
                  onChange={onManufacturerIdChange}
                  disabled={isEdit}
                  error={error}
                  externalEndAdornment={
                    isManufacturerDataLoading && isManufacturerInfoFetching
                      ? {
                          endAdornment: (
                            <div className={classes.inputLoader}>
                              <LoadingSpinner size={'1.5rem'} />
                            </div>
                          ),
                        }
                      : undefined
                  }
                />
              );
            }}
          </FormField>
          {!!deviceId && isEdit && isLocationFieldDirty && (
            <AttachmentValidation
              assetId={deviceId}
              data={data?.links || undefined}
              deviceAttachment={deviceAttachment}
              variant={deviceAttachment ? 'error' : 'success'}
            />
          )}
          {isEdit && (
            <div className={classes.pinDevice}>
              <FormField<IUpsertDeviceForm> fieldName="pinned">
                {(props) => (
                  <FormControlLabel
                    control={<Checkbox checked={props.value} onChange={props.onChange} />}
                    label={content.PIN_SENSOR_LABEL}
                  />
                )}
              </FormField>
              <FormHelperText>{content.PIN_SENSOR_HELPER} </FormHelperText>
            </div>
          )}
          {(defaultValues?.manufacturerId || isManufacturerInfoSuccess) && (
            <>
              <div className={classes.formSection}>{content.ADDITIONAL_INFORMATION}</div>
              <div className={classes.input}>
                <DeviceTypeControl<IUpsertDeviceForm> disabled fieldName="deviceType" />
              </div>
              {signalReceiverType && (
                <FormField<IUpsertDeviceForm> control={form.control} fieldName="signalReceiverType">
                  {(props) => (
                    <Input
                      {...props}
                      disabled
                      className={classes.input}
                      label={content.SIGNAL_RECEIVER_TYPE}
                      shrink={!!props.value}
                    />
                  )}
                </FormField>
              )}
              <div className={classes.inputRow}>
                <FormField<IUpsertDeviceForm> control={form.control} fieldName="manufacturer">
                  {(props) => (
                    <Input
                      {...props}
                      disabled
                      className={classes.input}
                      label={content.MANUFACTURER_LABEL}
                      shrink={!!props.value}
                    />
                  )}
                </FormField>
                <FormField<IUpsertDeviceForm> control={form.control} fieldName="model">
                  {(props) => (
                    <Input
                      {...props}
                      disabled
                      className={classes.input}
                      label={content.MODEL_LABEL}
                      shrink={!!props.value}
                    />
                  )}
                </FormField>
              </div>
              <div className={classes.inputRow}>
                <FormField<IUpsertDeviceForm> control={form.control} fieldName="commissionDate">
                  {(props) => (
                    <Tooltip
                      placement={'top'}
                      disableHoverListener={isEdit}
                      disableTouchListener={isEdit}
                      text={content.SET_COMMISSION_DATE_INFO_TOOLTIP}
                    >
                      <Box sx={{ display: 'flex', width: '100%' }}>
                        <SingleDatePicker
                          variant="datetime"
                          label={content.COMMISSION_DATE_LABEL}
                          helperText={content.ORGANIZATION_TIME_ZONE(timezone?.name)}
                          timezone={timezone?.id}
                          disabled={!isEdit}
                          {...props}
                        />
                      </Box>
                    </Tooltip>
                  )}
                </FormField>
              </div>
              <FormField<IUpsertDeviceForm> fieldName="description">
                {(props) => (
                  <Input
                    className={classes.input}
                    label={content.DESCRIPTION_LABEL}
                    placeholder={content.DESCRIPTION_PLACEHOLDER}
                    {...props}
                  />
                )}
              </FormField>
            </>
          )}
          {deviceType === DEVICE_TYPE.PULSE_METER && (
            <>
              <div className={classes.formSection}>
                <Typography fontWeight="bold">{content.CONECTED_METER}</Typography>
              </div>
              <div className={classes.meterType}>
                <Typography className={classes.meterTypeLabel}>{content.METER_TYPE}</Typography>
                <ToggleButtons<TSubtypesType>
                  options={meterTypeToggleOptions}
                  value={meterType}
                  onChange={(_, value) => {
                    if (value !== null) {
                      clearPulseMeter();
                      setMeterType(value);
                    }
                  }}
                />
              </div>

              <div className={classes.inputRow}>
                <MeterModelControl
                  isCompound={isCompound}
                  subtypes={subtypes}
                  timezoneName={orgDetails?.timeZone ?? ''}
                  childrenIds={childrenManufacturerIds}
                />
              </div>
            </>
          )}
          <div className={classes.buttonWrapper}>
            {!isMobile && (
              <Button className={classes.button} variant="outlined" data-testid="new-device-cancel" onClick={onCancel}>
                {content.BUTTON_CANCEL}
              </Button>
            )}
            <Button
              className={classes.button}
              variant="contained"
              data-testid={isEdit ? 'new-device-update' : 'new-device-create'}
              onClick={handleSubmit}
              disabled={
                !form.formState.isValid ||
                disableSubmit ||
                isManufacturerInfoFetching ||
                (isEdit && !form.formState.isDirty) ||
                (!isCommissionDateValid && isEdit) ||
                (!!deviceId && isEdit && isLocationFieldDirty && !!deviceAttachment)
              }
            >
              {isEdit ? content.BUTTON_UPDATE : content.BUTTON_CREATE}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Paper>
  );
};

export { UpsertDevice };
