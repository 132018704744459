import {
  TCalibrationParamsSchema,
  TCalibrationResponseSchema,
  useProcessCalibration,
  useSaveCalibration,
} from '@marlin/asset/data-access/device';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { AxiosError } from 'axios';
import { useCallback, useContext, useState } from 'react';

import { content } from '../content';
import { TUomType } from './calibration-forms/uom-toggle-options';
import { CalibrationModalBody } from './calibration-modal-body.component';
import { CalibrationModalTitle } from './calibration-modal-title.component';
import { InfoModalBody } from './info-modal/info-modal-body.component';
import { InfoModalFooter } from './info-modal/info-modal-footer.component';
import { InfoModalTitle } from './info-modal/info-modal-title.component';
import { IInfoModalData } from './info-modal/types';
import { isNotEnoughWater, notEnoughWater, notEnoughWaterLabel } from './not-enough-water';
import { ICalibration, handleErrorCodes } from './types';

interface IUseCalibration {
  childrenIds: string[];
  manufacturerId: string;
  timezoneName: string;
  modalClassName: string;
}

type TOnConfirm = (subtypeId: string, manufacturerId: string) => (calibration: TCalibrationParamsSchema) => void;
type TOnSaveLater = (calibration: TCalibrationParamsSchema, subtypeId: string, manufacturerId: string) => void;

export const useCalibration = ({ timezoneName, modalClassName }: IUseCalibration) => {
  const processCalibrationMutation = useProcessCalibration();
  const saveCalibrationForLater = useSaveCalibration();
  const { modalDispatch } = useContext(ModalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [calibrationValue, setCalibrationValue] = useState<ICalibration | undefined>(undefined);
  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const handleOpenInfoModal = useCallback(
    ({ title, text, onConfirm, onCancel, confirmLabel, cancelLabel, testId }: IInfoModalData) => {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <InfoModalTitle title={title} testId={testId} />,
          body: <InfoModalBody text={text} testId={testId} />,
          footer: (
            <InfoModalFooter
              onConfirm={onConfirm}
              onCancel={onCancel}
              confirmLabel={confirmLabel}
              cancelLabel={cancelLabel}
              testId={testId}
            />
          ),
        },
      });
    },
    [modalDispatch]
  );

  const handleOpenCalibrationModal = useCallback(
    (
      subtypeId: string,
      manufacturerId: string,
      onConfirm: TOnConfirm,
      onSaveLater: TOnSaveLater,
      defaultValues?: TCalibrationParamsSchema
    ) => {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <CalibrationModalTitle />,
          body: (
            <CalibrationModalBody
              defaultValues={defaultValues}
              timezoneName={timezoneName}
              manufacturerId={manufacturerId}
              onConfirm={onConfirm(subtypeId, manufacturerId)}
              onCancel={handleCloseModal}
              onSaveForLater={onSaveLater}
            />
          ),
          modalClassName,
        },
      });
      return;
    },
    [modalDispatch, timezoneName, handleCloseModal, modalClassName]
  );

  const onSuccess = useCallback(
    (calibrationResponse: TCalibrationResponseSchema, subtypeId: string) => {
      enqueueSnackbar(content.CALIBRATION_MODAL.ON_PROCESS_SUCCESS, {
        variant: 'success',
        preventDuplicate: true,
      });
      const calibrationResult = {
        value: calibrationResponse.unitsPerPulse ?? undefined,
        timestamp: calibrationResponse.calculationTimestamp ?? undefined,
        uom: calibrationResponse.measurementUnit ?? undefined,
      };
      setCalibrationValue(calibrationResult);
    },
    [enqueueSnackbar]
  );

  const onError = useCallback(
    (e: AxiosError<{ errorCode: number }>) => {
      enqueueSnackbar(handleErrorCodes(e?.response?.data?.errorCode), {
        variant: 'error',
        preventDuplicate: true,
      });
    },
    [enqueueSnackbar]
  );

  const basicFlow = useCallback(
    async (calibration: TCalibrationParamsSchema, subtypeId: string, manufacturerId: string) => {
      try {
        const calibrationResponse = await processCalibrationMutation.mutateAsync({ manufacturerId, calibration });
        onSuccess(calibrationResponse, subtypeId);
        handleCloseModal();
      } catch (e) {
        onError(e as AxiosError<{ errorCode: number }>);
      }
    },
    [handleCloseModal, onError, onSuccess, processCalibrationMutation]
  );

  const interruptedFlowCancel = useCallback(
    async (calibration: TCalibrationParamsSchema, subtypeId: string, manufacturerId: string, onConfirm: TOnConfirm) => {
      handleCloseModal();
      handleOpenCalibrationModal(subtypeId, manufacturerId, onConfirm, saveForLater, calibration);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleCloseModal, handleOpenCalibrationModal]
  );

  const interruptedFlowContinue = useCallback(
    async (
      calibration: TCalibrationParamsSchema,
      subtypeId: string,
      manufacturerId: string,
      onConfirm: TOnConfirm,
      onSaveForLater: TOnSaveLater
    ) => {
      try {
        const calibrationResponse = await processCalibrationMutation.mutateAsync({ manufacturerId, calibration });
        onSuccess(calibrationResponse, subtypeId);
        handleCloseModal();
      } catch (e) {
        onError(e as AxiosError<{ errorCode: number }>);
        handleOpenCalibrationModal(subtypeId, manufacturerId, onConfirm, onSaveForLater);
      }
    },
    [handleCloseModal, handleOpenCalibrationModal, onError, onSuccess, processCalibrationMutation]
  );

  const interruptedSaveForLaterFlowContinue = useCallback(
    async (
      calibration: TCalibrationParamsSchema,
      subtypeId: string,
      manufacturerId: string,
      onConfirm: TOnConfirm,
      onSaveForLater: TOnSaveLater
    ) => {
      try {
        await saveCalibrationForLater.mutateAsync({ manufacturerId, calibration });
        enqueueSnackbar(content.READING_HAS_BEEN_SAVED, {
          variant: 'success',
          preventDuplicate: true,
        });
        handleCloseModal();
      } catch (e) {
        onError(e as AxiosError<{ errorCode: number }>);
        handleOpenCalibrationModal(subtypeId, manufacturerId, onConfirm, onSaveForLater, calibration);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveCalibrationForLater, handleCloseModal, handleOpenCalibrationModal, processCalibrationMutation]
  );

  const interruptedFlow = useCallback(
    async (
      calibration: TCalibrationParamsSchema,
      subtypeId: string,
      manufacturerId: string,
      onConfirm: TOnConfirm,
      onSaveForLater: TOnSaveLater
    ) => {
      handleCloseModal();
      handleOpenInfoModal({
        title: content.WATER_MODAL.TITLE,
        text: content.WATER_MODAL.BODY(
          notEnoughWater[calibration.measurementUnit as TUomType],
          notEnoughWaterLabel[calibration.measurementUnit as TUomType]
        ),
        cancelLabel: content.WATER_MODAL.CANCEL,
        confirmLabel: content.WATER_MODAL.CONFIRM,
        onConfirm: () => interruptedFlowContinue(calibration, subtypeId, manufacturerId, onConfirm, onSaveForLater),
        onCancel: () => interruptedFlowCancel(calibration, subtypeId, manufacturerId, onConfirm),
        testId: 'water-modal',
      });
    },
    [handleCloseModal, handleOpenInfoModal, interruptedFlowCancel, interruptedFlowContinue]
  );

  const processCalibration = useCallback(
    (subtypeId: string, manufacturerId: string) => async (calibration: TCalibrationParamsSchema) => {
      if (isNotEnoughWater(calibration)) {
        return interruptedFlow(calibration, subtypeId, manufacturerId, processCalibration, saveForLater);
      }
      return basicFlow(calibration, subtypeId, manufacturerId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [basicFlow, interruptedFlow]
  );

  const saveForLater = useCallback(
    (calibration: TCalibrationParamsSchema, subtypeId: string, manufacturerId: string) => {
      handleCloseModal();
      handleOpenInfoModal({
        title: content.READING_IS_SAVED,
        text: content.READING_IS_SAVED_BODY,
        cancelLabel: content.GO_BACK_TO_CALIBRATION,
        confirmLabel: content.OK,
        onConfirm: () =>
          interruptedSaveForLaterFlowContinue(calibration, subtypeId, manufacturerId, processCalibration, saveForLater),
        onCancel: () => interruptedFlowCancel(calibration, subtypeId, manufacturerId, processCalibration),
        testId: 'save-for-later-confirm-modal',
      });
    },
    [
      interruptedFlowCancel,
      interruptedSaveForLaterFlowContinue,
      handleCloseModal,
      handleOpenInfoModal,
      processCalibration,
    ]
  );

  const handleCalibrationButtonPress = useCallback(
    (subtypeId: string, manufacturerId: string, defaultValues?: TCalibrationParamsSchema) => {
      return handleOpenCalibrationModal(subtypeId, manufacturerId, processCalibration, saveForLater, defaultValues);
    },
    [handleOpenCalibrationModal, processCalibration, saveForLater]
  );

  return { handleCalibrationButtonPress, calibrationValue, setCalibrationValue };
};
