import { TBucketOption, getDateFromWithAdjustByBucket, getDateToWithAdjustByBucket } from '@marlin/shared/utils-chart';
import { dateAdapter, millisecondsInHour } from '@marlin/shared/utils-common-date';

import { getNewBucketOption } from './bucket-options';

interface ISelection {
  min: number;
  max: number;
}

const twoHoursInMilliseconds = 2 * millisecondsInHour;

export const isSelectionGreaterThanTwoHours = (selectionX1: number, selectionX2: number): boolean =>
  Math.abs(selectionX2 - selectionX1) > twoHoursInMilliseconds;

export const getNewSelection = (
  newSelection: ISelection,
  previousSelection: ISelection,
  isFlowBarChart: boolean,
  newBucketOption?: TBucketOption
): ISelection => {
  const currentSelection = isFlowBarChart
    ? getNewSelectionWithBucketOption(newSelection, newBucketOption)
    : newSelection;

  const isCurrentSelectionGreaterThanTwoHours = isSelectionGreaterThanTwoHours(
    currentSelection.min,
    currentSelection.max
  );
  const isPreviousSelectionGreaterThanTwoHours = isSelectionGreaterThanTwoHours(
    previousSelection.min,
    previousSelection.max
  );
  const selectionOffset = Math.ceil(
    (twoHoursInMilliseconds - Math.abs(currentSelection.max - currentSelection.min)) / 2
  );

  if (isCurrentSelectionGreaterThanTwoHours) {
    return currentSelection;
  }

  const previousSelectionDiff = Math.abs(previousSelection.max - previousSelection.min);
  const currentSelectionDiff = Math.abs(currentSelection.max - currentSelection.min);

  if (isPreviousSelectionGreaterThanTwoHours) {
    return {
      min: currentSelection.min - selectionOffset,
      max: currentSelection.max + selectionOffset,
    };
  }

  if (previousSelectionDiff === currentSelectionDiff) {
    return currentSelection;
  }

  return previousSelection;
};

const getNewSelectionWithBucketOption = (currentSelection: ISelection, newBucketOption?: TBucketOption): ISelection => {
  const dateDifferenceInMinutes =
    dateAdapter.date?.(currentSelection.max)?.diff(dateAdapter.date?.(currentSelection.min), 'minutes') ?? 0;
  const dateDifferenceInMinutesPlusMargin = dateDifferenceInMinutes + 1;

  const bucketOption = newBucketOption ? newBucketOption : getNewBucketOption(dateDifferenceInMinutesPlusMargin);

  const dateFrom = getDateFromWithAdjustByBucket(dateAdapter.date?.(currentSelection.min), bucketOption);

  const dateTo = getDateToWithAdjustByBucket(dateAdapter.date?.(currentSelection.max), bucketOption);

  return { min: dateFrom?.valueOf() ?? 0, max: dateTo?.valueOf() ?? 0 };
};
