import {
  AVERAGING_FUNCTION_FILTER,
  BucketOption,
  getBucketSizeForBarChart,
  getDatesForBarChart,
  getRangeWithMargins,
  getRawDataChartFunctionType,
} from '@marlin/shared/utils-chart';
import { z } from 'zod';

export const AnalyticsTelemetryRequestParams = z.object({
  requestedTelemetry: z.array(
    z.object({
      manufacturerId: z.string(),
      datapoints: z.array(z.string()),
    })
  ),
  dateFrom: z.string(),
  dateTo: z.string(),
  numberOfBuckets: z.number().optional(),
  averagingFunctionFilter: z.number().optional(),
  chartDisplayType: z.string().optional(),
  bucketOption: BucketOption.optional(),
  isCustomPeriod: z.boolean().optional(),
});
export type TAnalyticsTelemetryRequestParams = z.infer<typeof AnalyticsTelemetryRequestParams>;

export const BucketedRangeAnalyticsTelemetrySchema = AnalyticsTelemetryRequestParams.transform(
  ({ averagingFunctionFilter, requestedTelemetry, ...data }) => {
    const chartFunctionType = getRawDataChartFunctionType(
      data.dateFrom,
      data.dateTo,
      averagingFunctionFilter,
      data.chartDisplayType === 'bar'
    );

    const {
      from: dateFrom,
      to: dateTo,
      bucketSize,
    } = getRangeWithMargins(
      data.dateFrom,
      data.dateTo,
      data.numberOfBuckets,
      chartFunctionType === AVERAGING_FUNCTION_FILTER.RAW ? 50 : 100
    );

    if (data.chartDisplayType === 'bar') {
      const bucketSizeForBarChart = getBucketSizeForBarChart(data.bucketOption);
      const { dateFrom: dateFromForBarChart, dateTo: dateToForBarChart } = getDatesForBarChart(
        data.dateFrom,
        data.dateTo,
        data.bucketOption
      );

      return {
        requestedTelemetry,
        dateFrom: dateFromForBarChart,
        dateTo: dateToForBarChart,
        bucketSizeInMinutes: bucketSizeForBarChart,
        chartFunctionType,
      };
    }

    return {
      requestedTelemetry,
      dateFrom,
      dateTo,
      bucketSizeInMinutes: bucketSize,
      chartFunctionType,
    };
  }
);
