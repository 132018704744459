import { useAnalyticsTelemetry } from '@marlin/asset/data-access/telemetry';
import {
  AVERAGING_FUNCTION_FILTER,
  IChartSeries,
  TChartDisplayType,
  TChartType,
  getBucketSize,
  getNumberOfBuckets,
  useDuration,
} from '@marlin/shared/utils-chart';
import { useMemo } from 'react';

import { useChartDataProcessing } from '../../hooks/use-chart-data-processing';
import { useChartingColors } from '../../hooks/use-charting-colors.hook';
import { IRequestedTelemetry, TSelectedDatapoint } from '../../types';
import { IRangeFilterState } from './use-chart-range-reducer.hook';

export interface IChartModalDataProps {
  requestedTelemetry: IRequestedTelemetry[];
  activeRequestedTelemetry: IRequestedTelemetry[];
  from: moment.Moment | null;
  to: moment.Moment | null;
  chartId: string;
  rangeFilter: IRangeFilterState;
  chartDisplayType: TChartDisplayType;
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER;
  chartType: TChartType;
}

export interface IChartModalData {
  chartData: IChartSeries[];
  isFetching: boolean;
  isLoading: boolean;
  from: number;
  to: number;
  totalVolume: string | null;
  min: number;
  max: number;
  handleZoomChange: (zoom?: { min: number; max: number }) => void;
}

export const useChartModalData = ({
  requestedTelemetry,
  activeRequestedTelemetry,
  from: fromProps,
  to: toProps,
  chartId,
  rangeFilter,
  chartDisplayType,
  averagingFunctionFilter,
  chartType,
}: IChartModalDataProps): IChartModalData => {
  const colors = useChartingColors();

  const {
    fromDate: from,
    toDate: to,
    minSelection,
    maxSelection,
    handleZoomChange,
    // isZoomed,
    // zoomedRange,
  } = useDuration({
    key: chartId,
    rangeFilter,
    fromDate: fromProps,
    toDate: toProps,
  });

  const numberOfBuckets = useMemo(
    () =>
      getNumberOfBuckets({
        minSelection,
        maxSelection,
        rangeFilter: rangeFilter.range,
        shouldHaveSpecialCalculations: chartId === 'flow',
      }),
    [minSelection, maxSelection, rangeFilter.range, chartId]
  );

  const bucketPerMinute = useMemo(() => getBucketSize(from, to, numberOfBuckets), [from, numberOfBuckets, to]);

  const chartDatapoints: TSelectedDatapoint[] = useMemo(
    () =>
      requestedTelemetry.map((item, index) => ({
        manufacturerId: item.manufacturerId,
        label: item.name,
        equipmentName: 'Nexa Sensors',
        id: `${item.manufacturerId}-${item.name}`,
        locationId: item.locationId,
        locationName: item.locationName,
        type: chartType === 'custom' ? undefined : chartType,
        isActive: true,
        name: item.datapoints[0],
        parentType: 'sensor',
        color: colors[index % colors.length],
      })),
    [chartType, requestedTelemetry, colors]
  );

  const { data, isFetching, isLoading } = useAnalyticsTelemetry({
    dateFrom: from,
    dateTo: to,
    requestedTelemetry: activeRequestedTelemetry,
    numberOfBuckets,
    averagingFunctionFilter:
      chartType === 'flow' && chartDisplayType === 'bar' ? AVERAGING_FUNCTION_FILTER.SUM : averagingFunctionFilter,
  });

  const { chartData, min, max, totalVolume } = useChartDataProcessing({
    data: data?.telemetryData || [],
    bucketPerMinute,
    minSelection,
    maxSelection,
    chartDatapoints,
    parsedActiveDatapoints: requestedTelemetry,
    chartType: chartType,
    chartDisplayType,
  });

  return {
    chartData,
    isFetching,
    isLoading,
    from: minSelection,
    to: maxSelection,
    totalVolume,
    min,
    max,
    handleZoomChange,
  };
};
