import { MultiCharts } from '@marlin/asset/feature/multi-charts';
import { Sustainability } from '@marlin/asset/feature/sustainability';
import { environment } from '@marlin/environment';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const EmbeddedAnalyticsRoutes = (): JSX.Element => {
  return (
    <Route path={routes.analytics.root}>
      <Route
        index
        element={
          <PermissionGuard redirectTo={routes.home.dashboard} permissions={[PERMISSIONS.DATA_EXPORT]}>
            <MultiCharts />
          </PermissionGuard>
        }
      />
      {environment.module.features.sustainability && (
        <Route path={routes.analytics.sustainability.root} element={<Sustainability />} />
      )}
    </Route>
  );
};
