import { INTERNAL_ROLE, ROLE } from '@marlin/shared/utils-role';

import { content } from '../content';

export const availableRoles = [
  {
    value: ROLE.ADMIN,
    name: content.ROLE_ADMIN_LABEL,
    helperText: content.ROLE_ADMIN_HELPER_TEXT,
  },
  {
    value: ROLE.VIEWER,
    name: content.ROLE_VIEWER_LABEL,
    helperText: content.ROLE_VIEWER_HELPER_TEXT,
  },
];

export const supportRoles = [
  {
    value: INTERNAL_ROLE.SUPPORT,
    name: content.ROLE_SUPPORT_LABEL,
    helperText: content.ROLE_SUPPORT_HELPER_TEXT,
  },
  {
    value: INTERNAL_ROLE.CSM,
    name: content.ROLE_CSM_LABEL,
    helperText: content.ROLE_CSM_HELPER_TEXT,
  },
  {
    value: INTERNAL_ROLE.BUCS,
    name: content.ROLE_BUCS_LABEL,
    helperText: content.ROLE_BUCS_HELPER_TEXT,
  },
];
