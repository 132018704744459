import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { MODEL } from '@marlin/shared/utils/zod';
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

import { maxLabelLength } from '../../const';
import { calculateCeilSnappedValue } from '../../use-snap-to-grid.hook';
import { useCommonStyles } from '../common.styles';
import { NodeTooltip } from '../utils/node-tooltip.component';
import { SanitizationHeader } from './components/sanitization-header.component';
import { StartupHeader } from './components/startup-header.component';
import { RegisteredEquipmentContext } from './hooks/use-registered-equipment-context';
import { useRegisteredEquipment } from './hooks/use-registered-equipment.hook';
import { TNodeData, TStatus } from './types';
import {
  getBackgroundByEquipmentStatus,
  getBorderColorByEquipmentStatus,
  getColumnPosition,
  getEquipmentIcon,
} from './utils/utils';

interface IStylesProps {
  status?: TStatus;
  nodeHeight?: number | null;
  nodeWidth?: number | null;
}

const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { status, nodeWidth, nodeHeight }) => ({
  name: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '160%',
    '& > span': {
      height: theme.typography.pxToRem(26),
      color: getBorderColorByEquipmentStatus(status, theme),
      marginLeft: theme.typography.pxToRem(8),
    },
    '& > svg': {
      height: theme.typography.pxToRem(26),
      color: getBorderColorByEquipmentStatus(status, theme),
    },
  },
  header: {
    backgroundColor: getBackgroundByEquipmentStatus(status, theme),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(2),
    padding: theme.typography.pxToRem(8),
    border: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderRadius: theme.typography.pxToRem(8),
  },

  headerDefault: {
    backgroundColor: getBackgroundByEquipmentStatus(status, theme),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(2),
    padding: theme.typography.pxToRem(8),
    border: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderRadius: `0 0 ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)}`,
  },
  emptyEquipment: {
    width: nodeWidth ? calculateCeilSnappedValue(nodeWidth) : 'auto',
    height: nodeHeight ? 48 : 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  handleContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  leftHandle: {
    position: 'absolute',
    left: 0,
  },
  rightHandle: {
    position: 'absolute',
    left: '100%',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

const basicMargin = 0;
const handleMargin = 8;

const getGroupMargin = (group: number, totalRows: number) => {
  return (group - Math.ceil(totalRows / 2)) * handleMargin;
};

export const RegisteredEquipmentNodeDefault = ({ data, id }: NodeProps<TNodeData>) => {
  const { getNode } = useReactFlow();
  const node = getNode(id);
  const registeredEquipment = useRegisteredEquipment(data);
  const { headerStatus, modeValue } = registeredEquipment;
  const { classes, cx } = useStyles({ nodeWidth: node?.width, nodeHeight: node?.height, status: headerStatus });
  const { classes: commonClasses } = useCommonStyles();
  const sliceName = !!data?.name && data?.name?.length > maxLabelLength;
  const rowsNumber = Math.max(...(data.metadata?.metadataGroups?.map((group) => group.row) ?? [0]));

  return (
    <RegisteredEquipmentContext.Provider value={registeredEquipment}>
      {modeValue === 'startup' && (
        <StartupHeader lastReadingValues={data?.lastReadingValues || []} id={data.linkedId || ''} />
      )}
      {modeValue === 'sanitize' && (
        <SanitizationHeader
          lastReadingValues={data?.lastReadingValues || []}
          variant="default"
          id={data.linkedId || ''}
        />
      )}
      <div
        className={cx(
          modeValue === 'startup' || modeValue === 'sanitize' ? classes.headerDefault : classes.header,
          classes.emptyEquipment
        )}
      >
        <Link
          to={routes.equipmentDashboard.details.url(data.linkedId || '')}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <NodeTooltip text={data.name || ''} when={sliceName}>
            <div className={classes.name}>
              {sliceName ? `${data?.name?.slice(0, maxLabelLength)}...` : data?.name}{' '}
              {getEquipmentIcon(data?.metadata?.tags?.model as MODEL)}
            </div>
          </NodeTooltip>
        </Link>
        <div className={classes.handleContainer}>
          {data.metadata?.metadataGroups?.map((group) => {
            const direction = getColumnPosition(group.column, data.metadata?.layout?.columns || 1);
            return (
              <div
                className={direction === 'left' ? classes.leftHandle : classes.rightHandle}
                style={{
                  top: `calc(50% + ${
                    group.merged === 'column' ? basicMargin : getGroupMargin(group.row, rowsNumber)
                  }px)`,
                }}
              >
                <Handle
                  type="target"
                  id={group.handleId}
                  position={direction === 'left' ? Position.Left : Position.Right}
                  className={commonClasses.handle}
                />
                <Handle
                  type="source"
                  id={group.handleId}
                  position={direction === 'left' ? Position.Left : Position.Right}
                  className={commonClasses.handle}
                />
              </div>
            );
          })}
        </div>
      </div>
    </RegisteredEquipmentContext.Provider>
  );
};
