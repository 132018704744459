import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  viewing: {
    color: theme.palette.text.secondary,
    marginRight: theme.typography.pxToRem(8),
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  select: {
    width: theme.typography.pxToRem(220),
    backgroundColor: theme.palette.background.primary,
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
      width: '100%',
    },
  },
  custom: {
    padding: 0,
  },
  menuItem: {
    color: theme.palette.text.secondary,
  },
  iconWrapper: {
    marginLeft: theme.typography.pxToRem(8),
    marginRight: theme.typography.pxToRem(8),
    display: 'flex',
    alignSelf: 'center',
    color: alpha(theme.palette.text.primary, 0.54),
  },
  icon: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
  },
}));
