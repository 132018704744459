import { TChartDisplayType, TChartType } from '@marlin/shared/utils-chart';

export const getDefaultChartDisplayType = (chartType?: TChartType, storedDisplayType?: string): TChartDisplayType => {
  if (storedDisplayType && chartType === 'flow' && ['bar', 'rangeArea'].includes(storedDisplayType)) {
    return storedDisplayType as TChartDisplayType;
  }

  switch (chartType) {
    case 'flow':
      return 'bar';
    case 'leak':
    case 'onOff':
      return 'rangeBar';
    default:
      return 'line';
  }
};
