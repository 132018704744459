import { ElkNode, LayoutOptions } from 'elkjs/lib/elk.bundled';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { Node } from 'reactflow';

const getNodeSize = (node: Node, isParent: boolean) => {
  if (node.data.width && node.data.height) {
    return {
      width: node.data.width,
      height: node.data.height,
    };
  }
  if (isParent) {
    return {};
  }
  if (node.data.assetType === 'LOCATION' || node.data.assetType === 'EQUIPMENT') {
    return {
      width: 304,
      height: 96,
    };
  }
  return {};
};

export const arrayToTreeByKey = (array: Node[], key: string, nodeLevelLayoutOptions?: LayoutOptions): ElkNode[] => {
  const leafsByParentId: Record<string, Node[]> = groupBy(array, (node) => get(node, key) || 'root');

  const insertChildrenIntoTree = (node: ElkNode): ElkNode => {
    const children = leafsByParentId[node.id] as ElkNode[];

    if (children) {
      const conntectedChildren = children.map(insertChildrenIntoTree);

      return {
        ...node,
        ...getNodeSize(node as Node, children.length > 0),
        layoutOptions: nodeLevelLayoutOptions,
        children: conntectedChildren,
      };
    }

    return {
      ...node,
      ...getNodeSize(node as Node, false),
    };
  };

  return leafsByParentId['root'].map((node) => insertChildrenIntoTree(node as ElkNode));
};
